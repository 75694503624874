<app-header></app-header>
<!-- <div id="checkout-checkout" class="container">
    <ul class="breadcrumb">
        <li><a href="#"><i
                    class="fa fa-home"></i></a></li>
        <li><a href="#">Shopping
                Cart</a></li>
        <li><a
                href="#">Checkout</a>
        </li>
    </ul>
    <div class="row">
        <div id="content" class="col-sm-12">
            <h1 class="heading">Checkout</h1>
            <div class="panel-group" id="accordion">
                <div class="panel panel-default">
                    <div class="panel-heading">
                        <h4 class="panel-title">Step 1: Checkout Options</h4>
                    </div>
                    <div class="panel-collapse collapse" id="collapse-checkout-option">
                        <div class="panel-body"></div>
                    </div>
                </div>
                <div class="panel panel-default">
                    <div class="panel-heading">
                        <h4 class="panel-title">Step 2: Account &amp; Billing Details</h4>
                    </div>
                    <div class="panel-collapse collapse" id="collapse-payment-address">
                        <div class="panel-body"></div>
                    </div>
                </div>
                <div class="panel panel-default">
                    <div class="panel-heading">
                        <h4 class="panel-title">Step 3: Delivery Details</h4>
                    </div>
                    <div class="panel-collapse collapse" id="collapse-shipping-address">
                        <div class="panel-body"></div>
                    </div>
                </div>
                <div class="panel panel-default">
                    <div class="panel-heading">
                        <h4 class="panel-title">Step 4: Delivery Method</h4>
                    </div>
                    <div class="panel-collapse collapse" id="collapse-shipping-method">
                        <div class="panel-body"></div>
                    </div>
                </div>
                <div class="panel panel-default">
                    <div class="panel-heading">
                        <h4 class="panel-title">Step 5: Payment Method</h4>
                    </div>
                    <div class="panel-collapse collapse" id="collapse-payment-method">
                        <div class="panel-body"></div>
                    </div>
                </div>
                <div class="panel panel-default">
                    <div class="panel-heading">
                        <h4 class="panel-title">Step 6: Confirm Order</h4>
                    </div>
                    <div class="panel-collapse collapse" id="collapse-checkout-confirm">
                        <div class="panel-body"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- Horizontal Steppers -->
<div class="col-sm-12">
    <ul class="breadcrumb">
        <li><a><i class="fa fa-home"></i></a></li>
        <li><a >Check Out</a></li>
        
    </ul>
    <div class="row">
     <div class="col-sm-3">
       <button class ="font button button-circle" [ngClass]="addressBtn?'newColor':'defaultColor'" style="margin-left: 5px; margin-right: 5px;">
         <i class="fa fa-user-o font" ></i></button>
       <strong><span class="font">Address</span></strong>

     </div>

     <div class="col-sm-3 mt-1">
       <button class ="but button-circle font" [ngClass]="deliveryBtn?'newColor':'defaultColor'" style="margin-left: 5px; margin-right: 5px;">
        <i class="fa fa-truck ml-2 font" ></i>
       </button>
       
       <strong><span class="font">Delivery</span></strong>

     </div>

     <div class="col-sm-3 mt-1 mb-2">
       <button class ="button button-circle font" [ngClass]="reviewBtn?'newColor':'defaultColor'" style="margin-left: 5px; margin-right: 5px;">
        <i class="fa fa-ravelry ml-2 font" ></i>
       </button>
       
       <strong><span class="stepHeading font">Review</span></strong>

     </div>

     <div class="col-sm-3 mt-1">
       <button class ="button-circle font" [ngClass]="conformBtn?'newColor':'defaultColor'" style="margin-left: 5px; margin-right: 5px;">
        <i class="fa fa-id-card-o ml-2 font" ></i>
       </button>
       
       <strong><span class="font">Payment</span></strong>
     </div>
       
   
   </div>
   <div class="row mt-3">
       <div *ngIf=!addressBtn>
        <app-stepper></app-stepper>
       </div>
   <div *ngIf="addressBtn && !deliveryBtn">
    <app-checkout-address></app-checkout-address>
</div>
<div *ngIf="addressBtn && deliveryBtn && !reviewBtn" >
    <app-checkout-summary></app-checkout-summary>
</div>
<div *ngIf="reviewBtn">
    <app-checkout-payment></app-checkout-payment>
</div>

    <!-- <app-shop-cart></app-shop-cart> -->
   </div>
  
<div class="row">
    <app-footer></app-footer>
</div>

