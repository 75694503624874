import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { BasketService } from '../basket/basket.service';
import { CheckoutService } from './checkout.service';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit {
  addressBtn: boolean = false;
  deliveryBtn: boolean = false;
  reviewBtn: boolean = false;
  conformBtn: boolean = false;
  checkoutHead ?: string;
  // basketTotals$?: Observable<IBasketTotals>;

  constructor(private fb: FormBuilder, private checkoutService: CheckoutService, private basketService: BasketService) { } //private accountService: AccountService, private basketService: BasketService

  ngOnInit(): void {
    // this.checkoutService.checkOutHeader.subscribe(data=>{
    //   this.checkoutHead = data;
    // })
    this.stepChanges();
    this.getDeliveryMethodValue();
  }

  stepChanges() {
  
    this.checkoutService.addressValid.subscribe(data => {
      if (data) { this.addressBtn = true; }
    })
    this.checkoutService.deliveryMethod.subscribe(data => {
      if (data) { this.deliveryBtn = true; }
    })
    this.checkoutService.review.subscribe(data => {
      if (data) { this.reviewBtn = true; }
    })
    this.checkoutService.orderConfirm.subscribe(data => {
      if (data) { this.conformBtn = true; }
    })
  }
  getDeliveryMethodValue() {
    const basket = this.basketService.getCurrentBasketValue();
    if (basket.deliveryMethodId !== null) {
      // this.checkoutForm.get('deliveryForm').get('deliveryMethod').patchValue(basket.deliveryMethodId.toString());
    }
  }
}
