//import { CdkStepper } from '@angular/cdk/stepper';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CheckoutService } from '../checkout.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.css'],
})
export class StepperComponent  implements OnInit {

  addressForm: FormGroup = this.fb.group({
    firstName: ['', [Validators.required]],
    lastName: ['', [Validators.required]],
    street: ['', [Validators.required]],
    city: ['', [Validators.required]],
    state: ['', [Validators.required]],
    zipCode : ['', [Validators.required]],
  })

  addressData: any;
  error: any;
  successAlert: boolean = false;
  messageDisplay?: string;
  messageData?: string;
  warningAlert?: boolean = false;
  constructor(private fb: FormBuilder,private checkoutService : CheckoutService ) { }
  // addressForm: FormGroup | undefined;
  ngOnInit(): void {
    // this.checkoutService.checkOutHeader.next('Shipping Address');
    this.createCheckoutForm();
  }
  createCheckoutForm() {
    this.checkoutService.getUserAddress().subscribe(data=>{
      if(data){
        this.addressForm.patchValue({data})
      }
    })
  }
  onSubmit() {
    this.checkoutService.updateUserAddress(this.addressForm.value).subscribe(response  => {
      if(response != ''){
        console.log(response);
        Swal.fire({
          text: 'Saved Successfully',
          icon: 'success',
          timer: 2000
        });
        this.addressData = response
      } 
    },
      error =>{  
        if(error){       
        this.error= Swal.fire({
          text: 'Fill all the Field',
          icon: 'warning',
          timer:2000
        }); 
        console.log(this.error);
                 
      }}
    )
  }
  onNext(){
if(this.addressData){
  console.log(this.addressData);
  
  this.checkoutService.addressValid.next(this.addressData);
}
else{
  Swal.fire({
    text: 'Save the Data Before click Next',
    icon: 'warning',
    timer: 2000
  });
}
  }
}


