import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavigationExtras, Router } from '@angular/router';
import { BasketService } from 'src/app/basket/basket.service';
import { IBasket } from 'src/app/interfacelist/basket';
import { CheckoutService } from '../checkout.service';

declare var Stripe: any;
@Component({
  selector: 'app-checkout-payment',
  templateUrl: './checkout-payment.component.html',
  styleUrls: ['./checkout-payment.component.css']
})
export class CheckoutPaymentComponent implements AfterViewInit, OnDestroy {

  @ViewChild('cardNumber', { static: true }) cardNumberElement?: ElementRef;
  @ViewChild('cardExpiry', { static: true }) cardExpiryElement?: ElementRef;
  @ViewChild('cardCvc', { static: true }) cardCvcElement?: ElementRef;
  stripe: any;
  cardNumber: any;
  cardExpiry: any;
  cardCvc: any;
  cardErrors: any;
  cardHandler = this.onChange.bind(this);
  cardNumberValid = false;
  cardExpiryValid = false;
  cardCvcValid = false;
  isCardPay :boolean = true;
  paymentForm: FormGroup = this.fb.group({
    nameOnCard: ['', Validators.required]
  });

  constructor(private fb: FormBuilder, private basketService: BasketService, private checkoutService: CheckoutService, private router: Router) { }
  loading: boolean = false;
  shipAddress: any;
  deliveryManId: any;
  isCashPay: boolean = true;

  ngAfterViewInit(): void {
    this.stripe = Stripe('pk_test_51IgQyjSEFZHKfAMnQulmGDYOfLC7Hab5b9AUGrw0486mpODJHaxGFc9y2y0xjQ8RfkZU1NqkkdAFmS1iSk0Wxe2800qITR0MY7');
    const elements = this.stripe.elements();

    this.cardNumber = elements.create('cardNumber');
    this.cardNumber.mount(this.cardNumberElement?.nativeElement);
    this.cardNumber.addEventListener('change', this.cardHandler);

    this.cardExpiry = elements.create('cardExpiry');
    this.cardExpiry.mount(this.cardExpiryElement?.nativeElement);
    this.cardExpiry.addEventListener('change', this.cardHandler);

    this.cardCvc = elements.create('cardCvc');
    this.cardCvc.mount(this.cardCvcElement?.nativeElement);
    this.cardCvc.addEventListener('change', this.cardHandler);
  }
  ngOnDestroy() {

  }

  onChange(event: any) {
    if (event.error) {
      this.cardErrors = event.error.message;
    } else {
      this.cardErrors = null;
    }
    switch (event.elementType) {
      case 'cardNumber':
        this.cardNumberValid = event.complete;
        break;
      case 'cardExpiry':
        this.cardExpiryValid = event.complete;
        break;
      case 'cardCvc':
        this.cardCvcValid = event.complete;
        break;
    }
  }

  async submitOrder() {
    this.loading = true;
    const basket = this.basketService.getCurrentBasketValue();
    try {
      const createdOrder = await this.createOrder(basket);
      const paymentResult = await this.confirmPaymentWithStripe(basket);
      console.log(paymentResult);
      
      if (paymentResult.paymentIntent) {
        this.basketService.deleteLocalBasket(basket.id);
        const navigationExtras: NavigationExtras = { state: createdOrder };
        // this.basketService.removeCart();
    this.checkoutService.clearData();
        this.router.navigate(['/success'], navigationExtras);
      } else {
        console.log(paymentResult.error.message);
      }
      this.loading = false;
    } catch (error) {
      console.log(error);
      this.loading = false;
    }
  }

  private async confirmPaymentWithStripe(basket: any) {
    console.log(this.stripe);
    
    
    return this.stripe.confirmCardPayment(basket.clientSecret, {
      
      payment_method: {
        card: this.cardNumber,
        billing_details: {
          name: this.paymentForm.value['nameOnCard'],
          
        },
        }
      }
    );
  }
  private async createOrder(basket: IBasket) {
    const orderToCreate = this.getOrderToCreate(basket);
    // this.basketService.removeCart();
    // this.checkoutService.clearData();
    // this.router.navigateByUrl('/home');
    return this.checkoutService.createOrder(orderToCreate).toPromise();

  }

  private getOrderToCreate(basket: IBasket) {
    this.checkoutService.deliveryMethod.subscribe(data => {
      if (data) {
        this.deliveryManId = data;
      }
    });
    this.checkoutService.addressValid.subscribe(data => {
      this.shipAddress = data;
    })
    return {
      basketId: basket.id,
      deliveryMethodId: this.deliveryManId.id,
      shipToAddress: this.shipAddress
    };
  }
  onOptionChange(event: any) {
    if (event.target.value == 'cash') {
      this.isCashPay = false;
      this.isCardPay = true;
      this.checkoutService.orderConfirm.next(true);
    }
    else if(event.target.value == 'card'){
      this.isCardPay = false;
      this.isCashPay = false;
      this.checkoutService.orderConfirm.next(true);
    }

  }
}
