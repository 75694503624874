<app-header></app-header>
<div id="account-register" class="container">
    <ul class="breadcrumb">
      <li><a routerLink="/"><i class="fa fa-home"></i></a></li>
      <li><a href="#">Account</a></li>
      <li><a href="#">Register</a></li>
    </ul>
    <div class="row">
      <div id="content" class="col-md-12 col-sm-12 col-xs-12">
        <h1 class="heading" style="color: #3bac44;">Register Account</h1>
        <p>If you already have an account with us, please login at the <a
          routerLink="/register">login page</a>.</p>
        <form [formGroup]="registerForm" class="form-horizontal" (ngSubmit)="onSubmit()">
          <div id="content" class="col-md-6 col-sm-6 col-xs-12">
          <fieldset id="account">
            <legend style="color: #3bac44;">Your Personal Details</legend>
            <div class="form-group required" style="display:  none ;">
              <label class="col-sm-2 control-label">Customer Group</label>
              <div class="col-sm-10">
                <div class="radio">
                  <label>
                    <input type="radio" name="customer_group_id" value="1" checked="checked">
                    Default</label>
                </div>
              </div>
            </div>
            <div class="form-group required">
              <label class="col-sm-2 control-label" for="input-firstname" data-error="Required Field">First Name</label>
              <div class="col-sm-10">
                <input type="text" formControlName="displayName" value="" placeholder="First Name" id="input-firstname"
                  class="form-control" required>
                  <!-- <div *ngIf="f.displayName.touched && f.displayName.invalid" class="alert alert-danger">
                    <div *ngIf="f.displayName.errors.required">Name is required.</div>
                  </div> -->
              </div>
            </div>
            <div class="form-group required">
              <label class="col-sm-2 control-label" for="input-lastname">Last Name</label>
              <div class="col-sm-10">
                <input type="text" formControlName="lastname" value="" placeholder="Last Name" id="input-lastname"
                  class="form-control">
                  <!-- <div *ngIf="f.lastname.touched && f.lastname.invalid" class="alert alert-danger">
                    <div *ngIf="f.lastname.errors.required">Name is required.</div>
                  </div> -->
              </div>
            </div>
            <div class="form-group required">
              <label class="col-sm-2 control-label" for="input-email">E-Mail</label>
              <div class="col-sm-10">
                <input type="email" formControlName="email" value="" placeholder="E-Mail" id="input-email" class="form-control">
                <!-- <div *ngIf="f.email.touched && f.email.invalid" class="alert alert-danger">
                  <div *ngIf="f.email.errors.required">Name is required.</div>
                  <div *ngIf="f.email.errors.email">Please enter email id</div>
                </div> -->
              </div>
            </div>
            <div class="form-group required">
              <label class="col-sm-2 control-label" for="input-telephone">Telephone</label>
              <div class="col-sm-10">
                <input type="tel" formControlName="telephone" value=""  placeholder="Telephone" id="input-telephone"
                  class="form-control">
                  <!-- <div *ngIf="f.telephone.touched && f.telephone.invalid" class="alert alert-danger">
                    <div *ngIf="f.telephone.errors.required">Name is required.</div>
                    <div *ngIf="f.telephone.errors.minlength">Name should be 10 character.</div>
                  </div> -->
              </div>
            </div>
          </fieldset>
        </div>
          <div id="content" class="col-md-6 col-sm-6 col-xs-12">
          <fieldset>
            <legend style="color: #3bac44;">Your Password</legend>
            <div class="form-group required">
              <label class="col-sm-2 control-label" for="input-password">Password</label>
              <div class="col-sm-10">
                <input type="password" formControlName="password" value="" placeholder="Password" id="input-password"
                  class="form-control">
                  <!-- <div *ngIf="f.password.touched && f.password.invalid" class="alert alert-danger">
                    <div *ngIf="f.password.errors.required">Name is required.</div>
                    <div *ngIf="f.password.errors.minlength">Name should be 10 character.</div>
                  </div> -->
              </div>
            </div>
            <div class="form-group required">
              <label class="col-sm-2 control-label" for="input-confirm">Password Confirm</label>
              <div class="col-sm-10">
                <input type="password" formControlName="confirm" value="" placeholder="Password Confirm" id="input-confirm"
                  class="form-control">
                  <!-- <div *ngIf="f.confirm.touched && f.confirm.invalid" class="alert alert-danger">
                    <div *ngIf="f.confirm.errors.required">Name is required.</div>
                    <div *ngIf="f.confirm.errors.minlength">Name should be 10 character.</div>
                  </div> -->
              </div>
            </div>
          </fieldset>
          <fieldset>
            <legend style="color: #3bac44;">Newsletter</legend>
            <div class="form-group">
              <label class="col-sm-2 control-label">Subscribe</label>
              <div class="col-sm-10"> <label class="radio-inline">
                  <input type="radio" name="newsletter" value="1">
                  Yes</label>
                <label class="radio-inline">
                  <input type="radio" name="newsletter" value="0" checked="checked">
                  No</label>
              </div>
            </div>
          </fieldset>
        </div>
        <div id="content" class="col-md-6 col-sm-6 col-xs-12">
          <div class="buttons">
            <div class="pull-right">I have read and agree to the <a
              routerLink="/#"
                class="agree"><b>Privacy Policy</b></a>&nbsp;
              <input type="checkbox" name="agree" value="1">
              &nbsp;
              <button type="submit" name="Continue" class="btn btn-primary">Continue to Register</button>
            </div>
          </div>
        </div>
        </form>
      </div>
    </div>
  </div>
  <app-footer></app-footer>

  