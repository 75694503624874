<app-header></app-header>
<div id="account-login" class="container">
    <!-- <div class="alert alert-dismissible fade show"
     [ngClass]="{'alert-success':successAlert===true,'alert-warning':warningAlert===true}" role="alert"
        *ngIf="successAlert || warningAlert">
        <strong>{{messageData}}</strong> {{messageDisplay}}
        <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)=onClose();>
        <span aria-hidden="true">&times;</span>
        </button>
    </div> -->
    <ul class="breadcrumb">
        <li><a routerLink="/"><i class="fa fa-home"></i></a></li>
        <li><a href="#">Account</a></li>
        <li><a href="#">Login</a></li>
    </ul>
    <div class="row">
        <div id="content" class="col-md-12 col-sm-12 col-xs-12">
            <div class="row">
                <div class="col-md-6 col-xs-12">
                    <div class="well">
                        <h2 class="heading" style="text-align: left;">New Customer</h2>
                        <p style="color: #3bac44!important;"><b>Register Account</b></p>
                        <p>By creating an account you will be able to shop faster, be up to date on an order's
                            status, and keep track of the orders you have previously made.</p>
                        <a routerLink="/register" class="btn btn-primary">Continue to Register</a>
                    </div>
                </div>
                <div class="col-md-6 col-xs-12">
                    <div class="well">
                        <h2 class="heading" style="text-align: left;">Returning Customer</h2>
                        <form [formGroup]="loginForm" (ngSubmit)=" onSubmit()">
                            
                                
                            <div class="form-group" class="col-md-6">
                                <label class="control-label" for="input-email">E-Mail Address</label>
                                <input type="text" formControlName="email" value="" placeholder="E-Mail Address"
                                    class="form-control" required>
                                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                    <div *ngIf="f.email.errors.required" style="color: red;">First Name is required
                                    </div>
                                </div>
                            </div>
                            <div class="form-group" class="col-md-6">
                                <label class="control-label" for="input-password">Password</label>
                                <input type="password" formControlName="password" value="" placeholder="Password"
                                    id="input-password" class="form-control">
                                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                    <div *ngIf="f.password.errors.required" style="color: red;">First Name is required
                                    </div>
                                </div>
                                <!-- <a routerLink="/">Forgotten Password</a> -->
                            </div>
                            <button type="submit" name="Continue" class="btn btn-primary" style="margin-top: 15px;">Login</button><span
                                *ngIf="error" style="color: red;"> </span>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>