import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ProductlistComponent } from './productlist/productlist.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { LoginComponent } from './login/login.component';
import { ProductComponent } from './product/product.component';
import { RegisterComponent } from './register/register.component';
import { ShopcartComponent } from './shopcart/shopcart.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { prductApiService } from './services/productapi.service';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ContactusComponent } from './contactus/contactus.component';
import { AboutusComponent } from './aboutus/aboutus.component';

//import { CdkStepperModule } from '@angular/cdk/stepper';
import { StepperComponent } from './checkout/stepper/stepper.component';
import { CheckoutAddressComponent } from './checkout/checkout-address/checkout-address.component';
import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { CheckoutPaymentComponent } from './checkout/checkout-payment/checkout-payment.component';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { CheckoutSummaryComponent } from './checkout/checkout-summary/checkout-summary.component';
import { CheckoutSuccessComponent } from './checkout/checkout-success/checkout-success.component';
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    ProductlistComponent,
    CheckoutComponent,
    LoginComponent,
    ProductComponent,
    RegisterComponent,
    ShopcartComponent,
    ContactusComponent,
    AboutusComponent,
    StepperComponent,
    CheckoutAddressComponent,
    CheckoutPaymentComponent,
    CheckoutSummaryComponent,
    CheckoutSuccessComponent
         
  ],
  imports: [
    BrowserModule,
    NgxPaginationModule,
    AppRoutingModule,
    NgbModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    CommonModule
    
  ],
  exports: [],
  providers: [prductApiService,
  { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor,multi:true},],
  bootstrap: [AppComponent],
})
export class AppModule { }
