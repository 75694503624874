  
<!-- <div class="mt-4" [formGroup]="checkoutForm"> -->
    <div class="col-sm-12" style="margin-top: 20px;">
    <div class="row" [formGroup]="deliveryForm">
        <div class="col-sm-3"></div>
        <div class="col-sm-6">
            <div class="col-sm-6 form-group form-check" *ngFor="let method of deliveryMethods; let i=index" >
                <input type="radio"
                    id={{method.id}}
                    (click)="setShippingPrice(method)"
                    value={{method.id}}
                    formControlName="deliveryMethod"
                    class="form-check-input "
                    checked
                >
                <label for="{{method.id}}" class="form-check-label active">
                    <span>{{method.shortName}} - {{method.price | currency}}</span>
                    <br />
                    <span class="label-description">{{method.description}}</span>
                </label>
            </div>
        </div>
    </div>
</div>
<div class="pull-right">
    <button [disabled]="deliveryForm.invalid" class="btn btn-primary" (click)="onReview()">
        Go to review <i class="fa fa-angle-right float-left"></i>
    </button>
</div>

<!-- </div> -->

<!-- <div class="float-none d-flex justify-content-between flex-column flex-lg-row mb-5">
    <button class="btn btn-outline-primary" cdkStepperPrevious>
        <i class="fa fa-angle-left"></i> Back to address
    </button>
    <button [disabled]="checkoutForm.get('deliveryForm').invalid" class="btn btn-primary" cdkStepperNext>
        Go to review <i class="fa fa-angle-right"></i>
    </button>
</div> -->