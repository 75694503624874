<footer class="card">
    <div class="foot-top">
<footer>

  <div class="container-fluid">
    <div class="row">
      <div class="middle-footer">

        <div class="col-sm-4">
          <h5>Information
            <button type="button" class="btn btn-primary toggle collapsed" data-toggle="collapse"
              data-target="#info"></button>
          </h5>
          <div id="info" class="collapse footer-collapse">
            <ul class="list-unstyled">
              <li><a href="/about">About Us</a></li>
              <li><a href="#">Delivery Information</a></li>
              <!-- <li><a href="#">Privacy Policy</a></li>
              <li><a href="#">Terms &amp; Conditions</a></li>
              <li><a href="#">Contact Us</a></li> -->
              <!-- <li><a href="#">Site Map</a></li> -->
            </ul>
          </div>
        </div>
        <div class="col-sm-4">
          <h5>My Account
            <button type="button" class="btn btn-primary toggle collapsed" data-toggle="collapse"
              data-target="#account"></button>
          </h5>
          <div id="account" class="collapse footer-collapse">
            <ul class="list-unstyled lastb">
              <li><a href="/login">My Account</a></li>
              <!-- <li><a href="#">Order History</a></li> -->
              <!-- <li><a href="#">Wish List</a></li> -->
              <!-- <li><a href="#">Newsletter</a></li> -->
              <li><a href="#">Returns</a></li>
            </ul>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="xs-fab">
            <aside id="column-right">
              <div>
                <div>
                  <h5 class=""><span>Contact us</span>
                    <button type="button" class="btn btn-primary toggle collapsed" data-toggle="collapse"
                      data-target="#contact"></button>
                  </h5>
                  <div id="contact" class="collapse footer-collapse footcontact" *ngFor="let item of details">
                    <ul class="list-unstyled f-left" *ngIf="item.id == 1">
                      <li><i class="fa fa-map-marker"></i>{{item.contactName}},{{item.address}},
                        <br />{{item.city}},<br />{{item.state}}, <br />{{item.country}}.
                      </li>
                      <li><i class="fa fa-envelope"></i>{{item.email}}</li>
                      <li><i class="fa fa-phone"></i>{{item.mobileNo}}</li>
                    </ul>
                  </div>
                </div>
              </div>

            </aside>

          </div>
        </div>
      </div>


    </div>
    <div>
      <div class="foot-bottom">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-6 col-xs-12 f-social">
            <!-- <div class="foot-app">
                <div class="title-footer hidden-md hidden-sm hidden-xs">Download Our App</div>
                <ul class="list-unstyled inline-block">
                  <li><a title="AppStore" href="#"><img class="img-responsive" src="assets/images/catalog/app1.png"
                        alt="App Store"></a></li>
                  <li><a title="PlayStore" href="#"><img class="img-responsive" src="assets/images/catalog/app2.png"
                        alt="Play Store"></a></li>
                </ul>
              </div> -->
            <div class="text-right">
              <a href="#"><img class="img-responsive center-block" src="assets/images/catalog/payment.png"
                  alt="imgpayment"></a>
            </div>
          </div>
          <!-- <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 pull-right ">
             
            </div> -->
        </div>
      </div>
    </div>
  </div>
  <div class="foot-power text-center">
    <div class="container">
      <div class="row">
        <div class="copy col-xs-12">Powered By <a routerLink="/">CKS</a> Solutions &copy; 2021</div>
      </div>
    </div>
  </div>
</footer>
<a href="" id="scroll" title="Scroll to Top" style="display: block;">
  <i class="fa fa-angle-up"></i>
</a>