import { Component, Input, OnInit } from '@angular/core';
import { from } from 'rxjs';
import { Productlist } from '../interfacelist/productlist';
import { prductApiService } from '../services/productapi.service';
import {BasketService} from '../basket/basket.service';

// declare function slider() : any;
// declare function insbanner() : any;
// declare function feature() : any;
// declare function latest() : any;
// declare function best() : any;
// declare function special() : any;


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {


  constructor(private _prductApiService: prductApiService, private BasketService:BasketService){ }
  listcomments?: any;
  adddata?: any;
  // cartdata?: Addtocart[];
  passdata?:any;
  usertoken?:any;
  errormsg?:any;
  ascproduct?:any;
  descproduct?:any;
 
  ngOnInit() {
    // slider();
    // insbanner();
    // feature();
    // latest();
    // best();
    // special();
    this._prductApiService.getComments().subscribe(data=>{
        this.listcomments = data['data'];               
      }
    );
    this._prductApiService.productSlider().subscribe(data=>{
      
      this.adddata = data;    
  
    }
  );
  this._prductApiService.getPriceDesc().subscribe(data =>{
    this.ascproduct = data['data'];
  });
  this._prductApiService.getPriceAsc().subscribe(data =>{
    this.descproduct = data['data'];
  });
  }
    
  //@Input() product :Productlist;
addItemToBasket(index: number){  
  this._prductApiService.getComments().subscribe(data=>{
    this.adddata = data['data'][index];
    this.BasketService.addItemToBasket(this.adddata);
  }
);
}
}
