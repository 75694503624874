<nav id="top">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 hidden-xs">
          <ul class="social-links circle small list-inline hidden-sm-down" style="float: left;">
            <li class="twitter"><a href="#"><i class="fa fa-twitter"></i></a></li>
            <li class="skype"><a href="#"><i class="fa fa-skype"></i></a></li>
            <li class="linkedin"><a href="#"><i class="fa fa-linkedin"></i></a></li>
            <li class="googleplus"><a href="#"><i class="fa fa-google-plus"></i></a></li>
            <li class="youtube"><a href="#"><i class="fa fa-youtube-play"></i></a></li>
            <li class="facebook"><a href="#"><i class="fa fa-facebook"></i></a></li>
            <li class="pinterest"><a href="#"><i class="fa fa-pinterest"></i></a></li>
          </ul>
         
        </div>
        <div id="top-links" class="col-lg-6 col-md-6 col-sm-6 col-xs-12 pull-right">
          <div *ngFor="let item of details" class="pull-left" >
            <ul class="list-inline hidden-md-down headadd" *ngIf="item.id == 1">
             
              <li class="list-inline-item hidden-sm" ><i class="fa fa-map-marker"></i>{{item.contactName}},{{item.address}},{{item.city}},{{item.state}},{{item.country}}</li>
              <li><a href="index.php.html?route=information/contact"><i class="fa fa-phone"></i></a> <span
                  class="hidden-xs">{{item.mobileNo}}</span></li>
              <li class="list-inline-item"><i class="fa fa-envelope-o"></i> {{item.email}}</li>
           
            </ul>
          </div>
          <!-- <div class="pull-left">
            <form action="#" method="post"
              enctype="multipart/form-data" id="form-currency">
              <div class="btn-group">
                <button class="btn btn-link dropdown-toggle test" data-toggle="dropdown"> <strong>$</strong> <span
                    class="t-title">Currency</span>&nbsp;<i class="fa fa-caret-down"></i></button>
                <ul class="dropdown-menu">
                  <li>
                    <button class="currency-select btn btn-link btn-block" type="button" name="EUR">€ Euro</button>
                  </li>
                  <li>
                    <button class="currency-select btn btn-link btn-block" type="button" name="INR">₹ Indian
                      Rupee</button>
                  </li>
                  <li>
                    <button class="currency-select btn btn-link btn-block" type="button" name="GBP">£ Pound
                      Sterling</button>
                  </li>
                  <li>
                    <button class="currency-select btn btn-link btn-block" type="button" name="USD">$ US Dollar</button>
                  </li>
                </ul>
              </div>
              <input type="hidden" name="code" value="">
              <input type="hidden" name="redirect"
                value="#">
            </form>
          </div> -->
          <!-- <div class="pull-left">
            <form action="#" method="post"
              enctype="multipart/form-data" id="form-language">
              <div class="btn-group">
                <button class="btn btn-link dropdown-toggle test" data-toggle="dropdown">

                  <img src="assets/catalog/language/en-gb/en-gb.png" alt="English" title="English">
                  <span class="t-title">Language</span>&nbsp;<i class="fa fa-caret-down"></i></button>
                <ul class="dropdown-menu">
                  <li>
                    <button class="btn btn-link btn-block language-select" type="button" name="ar"><img
                        src="assets/catalog/language/ar/ar.png" alt="Arabic" title="Arabic"> Arabic</button>
                  </li>
                  <li>
                    <button class="btn btn-link btn-block language-select" type="button" name="en-gb"><img
                        src="assets/catalog/language/en-gb/en-gb.png" alt="English" title="English"> English</button>
                  </li>
                  <li>
                    <button class="btn btn-link btn-block language-select" type="button" name="bg-bg"><img
                        src="assets/catalog/language/bg-bg/bg-bg.png" alt="Bulgarian" title="Bulgarian"> Bulgarian</button>
                  </li>
                  <li>
                    <button class="btn btn-link btn-block language-select" type="button" name="tr-tr"><img
                        src="assets/catalog/language/tr-tr/tr-tr.png" alt="Türkçe" title="Türkçe"> Türkçe</button>
                  </li>
                  <li>
                    <button class="btn btn-link btn-block language-select" type="button" name="de-de"><img
                        src="assets/catalog/language/de-de/de-de.png" alt="German" title="German"> German</button>
                  </li>
                  <li>
                    <button class="btn btn-link btn-block language-select" type="button" name="es-es"><img
                        src="assets/catalog/language/es-es/es-es.png" alt="Spain" title="Spain"> Spain</button>
                  </li>
                  <li>
                    <button class="btn btn-link btn-block language-select" type="button" name="fr-fr"><img
                        src="assets/catalog/language/fr-fr/fr-fr.png" alt="French" title="French"> French</button>
                  </li>
                  <li>
                    <button class="btn btn-link btn-block language-select" type="button" name="it-it"><img
                        src="assets/catalog/language/it-it/it-it.png" alt="Italian" title="Italian"> Italian</button>
                  </li>
                  <li>
                    <button class="btn btn-link btn-block language-select" type="button" name="ka-ge"><img
                        src="assets/catalog/language/ka-ge/ka-ge.png" alt="Georgian" title="Georgian"> Georgian</button>
                  </li>
                  <li>
                    <button class="btn btn-link btn-block language-select" type="button" name="pl-pl"><img
                        src="assets/catalog/language/pl-pl/pl-pl.png" alt="Polish" title="Polish"> Polish</button>
                  </li>
                  <li>
                    <button class="btn btn-link btn-block language-select" type="button" name="sv-se"><img
                        src="assets/catalog/language/sv-se/sv-se.png" alt="Swedish" title="Swedish"> Swedish</button>
                  </li>
                  <li>
                    <button class="btn btn-link btn-block language-select" type="button" name="cs-cz"><img
                        src="assets/catalog/language/cs-cz/cs-cz.png" alt="czech" title="czech"> czech</button>
                  </li>
                </ul>
              </div>
              
              <input type="hidden" name="code" value="">
              <input type="hidden" name="redirect"
                value="#">
            </form>
          </div> -->
          <div *ngIf="name"class="pull-left" id="user-detail"><span style="color: white;"><b>{{name}} | </b> <button (click)="logout()" style="background-color: white;color: #3bac44;border: none;">Logout</button></span></div>           
        </div>
      </div>
    </div>
  </nav>
  <header class="hsticky">
    <div class="container">
      <div class="row">
        <div class="col-sm-2 col-xs-12 xslogo" style="margin-top: 25px;" *ngFor="let logo of logo">
          <div id="logo"><a routerLink="/"><img [src]="logo.logoImageUrl"
                title="Your Store" [alt]="logo.name" class="img-responsive"></a></div>
        </div>
        <div class="col-sm-8 deskmenu">
          <div class="hidden-xs">
            <div class="">
              <nav id="menu" class="navbar">
                <div class="navbar-header"><span id="category" class="visible-xs">Categories</span>
                  <button type="button" class="btn btn-navbar navbar-toggle" data-toggle="collapse"
                    data-target=".navbar-ex1-collapse"><i class="fa fa-bars"></i></button>
                </div>
                <div class="collapse navbar-collapse navbar-ex1-collapse">
                  <div *ngFor="let mainmenu of menu">
                  <ul class="nav navbar-nav" *ngIf="mainmenu.parentId==0">
                    <li class="dropdown"><a  routerLink="/productlist"
                        class="dropdown-toggle header-menu" data-toggle="dropdown">{{mainmenu.menuName}}<i
                          class="fa fa-angle-down pull-right enangle"></i></a>
                      <div  *ngFor="let list of menu">
                      <div class="dropdown-menu" *ngIf="mainmenu.id == list.parentId ">
                        <div class="dropdown-inner">
                          <ul class="list-unstyled">
                            <li class="dropdown-submenu"> <a
                              routerLink="/productlist" class="submenu-title">
                              {{list.menuName}}</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    </li>
                    <!-- <li><a href="#">POOJA PRODUCTS</a></li>
                    <li><a href="#">SPICES</a></li>
                    <li><a href="#">CHIKKI</a></li>
                    <li><a href="#">FLOURS</a></li> -->
                  </ul>
                </div>
                </div>
              </nav>
            </div>

          </div>


          <script type="text/javascript">
            function headermenu() {
              if (jQuery(window).width() < 992) {
                jQuery('ul.nav li.dropdown a.header-menu').attr("data-toggle", "dropdown");
              }
              else {
                jQuery('ul.nav li.dropdown a.header-menu').attr("data-toggle", "");
              }
            }
            $(document).ready(function () { headermenu(); });
            jQuery(window).resize(function () { headermenu(); });
            jQuery(window).scroll(function () { headermenu(); });
          </script>
        </div>
        <div class="col-sm-2 text-right xsic">
          <li class="xs-ac hidden-sm hidden-md hidden-lg">
          </li>

          <!--Account -->
          <ul class="list-inline u-prof">
            <li class="dropdown xsac u-acc"><a  title="My Account"
                class="dropdown-toggle" data-toggle="dropdown"><svg width="16px" height="16px">
                  <use xlink:href="#u-profile"></use>
                </svg></a>
              <ul class="dropdown-menu dropdown-menu-right acdrop">
                <li><a routerLink="/register"> <i class="fa fa-address-book-o"></i>
                    Register</a></li>
                <li><a  routerLink="/login"> <i class="fa fa-sign-in"></i> Login</a></li>
                <li><a href="#" id="wishlist-total" title="Wish List (0)"> <i
                      class="fa fa-heart"></i> <span>Wish List (0)</span></a></li>
                <div class="visible-xs">
                  <div class="pull-left">
                    <form action="#"
                      method="post" enctype="multipart/form-data" id="form-currency">
                      <div class="btn-group">
                        <button class="btn btn-link dropdown-toggle test" data-toggle="dropdown"> <strong>$</strong>
                          <span class="t-title">Currency</span>&nbsp;<i class="fa fa-caret-down"></i></button>
                        <ul class="dropdown-menu">
                          <li>
                            <button class="currency-select btn btn-link btn-block" type="button" name="EUR">€
                              Euro</button>
                          </li>
                          <li>
                            <button class="currency-select btn btn-link btn-block" type="button" name="INR">₹ Indian
                              Rupee</button>
                          </li>
                          <li>
                            <button class="currency-select btn btn-link btn-block" type="button" name="GBP">£ Pound
                              Sterling</button>
                          </li>
                          <li>
                            <button class="currency-select btn btn-link btn-block" type="button" name="USD">$ US
                              Dollar</button>
                          </li>
                        </ul>
                      </div>
                      <input type="hidden" name="code" value="">
                      <input type="hidden" name="redirect"
                        value="#">
                    </form>
                  </div>
                  <div class="pull-left">
                    <form action="#"
                      method="post" enctype="multipart/form-data" id="form-language">
                      <div class="btn-group">
                        <button class="btn btn-link dropdown-toggle test" data-toggle="dropdown">

                          <img src="assets/catalog/language/en-gb/en-gb.png" alt="English" title="English">
                          <span class="t-title">Language</span>&nbsp;<i class="fa fa-caret-down"></i></button>
                        <ul class="dropdown-menu">
                          <li>
                            <button class="btn btn-link btn-block language-select" type="button" name="ar"><img
                                src="assets/catalog/language/ar/ar.png" alt="Arabic" title="Arabic"> Arabic</button>
                          </li>
                          <li>
                            <button class="btn btn-link btn-block language-select" type="button" name="en-gb"><img
                                src="assets/catalog/language/en-gb/en-gb.png" alt="English" title="English"> English</button>
                          </li>
                          <li>
                            <button class="btn btn-link btn-block language-select" type="button" name="bg-bg"><img
                                src="assets/catalog/language/bg-bg/bg-bg.png" alt="Bulgarian" title="Bulgarian">
                              Bulgarian</button>
                          </li>
                          <li>
                            <button class="btn btn-link btn-block language-select" type="button" name="tr-tr"><img
                                src="assets/catalog/language/tr-tr/tr-tr.png" alt="Türkçe" title="Türkçe"> Türkçe</button>
                          </li>
                          <li>
                            <button class="btn btn-link btn-block language-select" type="button" name="de-de"><img
                                src="assets/catalog/language/de-de/de-de.png" alt="German" title="German"> German</button>
                          </li>
                          <li>
                            <button class="btn btn-link btn-block language-select" type="button" name="es-es"><img
                                src="assets/catalog/language/es-es/es-es.png" alt="Spain" title="Spain"> Spain</button>
                          </li>
                          <li>
                            <button class="btn btn-link btn-block language-select" type="button" name="fr-fr"><img
                                src="assets/catalog/language/fr-fr/fr-fr.png" alt="French" title="French"> French</button>
                          </li>
                          <li>
                            <button class="btn btn-link btn-block language-select" type="button" name="it-it"><img
                                src="assets/catalog/language/it-it/it-it.png" alt="Italian" title="Italian"> Italian</button>
                          </li>
                          <li>
                            <button class="btn btn-link btn-block language-select" type="button" name="ka-ge"><img
                                src="assets/catalog/language/ka-ge/ka-ge.png" alt="Georgian" title="Georgian">
                              Georgian</button>
                          </li>
                          <li>
                            <button class="btn btn-link btn-block language-select" type="button" name="pl-pl"><img
                                src="assets/catalog/language/pl-pl/pl-pl.png" alt="Polish" title="Polish"> Polish</button>
                          </li>
                          <li>
                            <button class="btn btn-link btn-block language-select" type="button" name="sv-se"><img
                                src="assets/catalog/language/sv-se/sv-se.png" alt="Swedish" title="Swedish"> Swedish</button>
                          </li>
                          <li>
                            <button class="btn btn-link btn-block language-select" type="button" name="cs-cz"><img
                                src="assets/catalog/language/cs-cz/cs-cz.png" alt="czech" title="czech"> czech</button>
                          </li>
                        </ul>
                      </div>
                      <input type="hidden" name="code" value="">
                      <input type="hidden" name="redirect"
                        value="#">
                    </form>
                  </div>
                </div>
              </ul>
            </li>
          </ul>
          <li class="dropdown header-btns desktop-search inline-block">
            <a href="#" class="dropdown-toggle" data-toggle="dropdown">
              <div class="d-search">
                <!-- <button>
                  <i class="fa fa-search"></i>
                </button> -->
              </div>
            </a>
            <div class="dropdown-menu my-search">
              <div id="search" class="input-group">
                <input type="text" name="search" value="" placeholder="Search" class="form-control input-lg">
                <span class="input-group-btn">
                  <!-- <button type="button" class="btn btn-default btn-lg" disabled><i class="fa fa-search"></i></button> -->
                </span>
              </div>
            </div>
          </li>
          <div id="cart" >
            <button routerLink="/shopcart" type="button" data-toggle="dropdown" data-loading-text="Loading..." class="dropdown-toggle"><i class="fa fa-shopping-basket"></i> <span id="cart-total"*ngIf="(basket$ | async) as basket">{{basket.items.length}}</span></button>
  <!-- <ul class="dropdown-menu pull-right">
        <li>
      <table class="table table-striped">
                <tr>
          <td class="text-center"> <a href="#"><img src="assets/images/kayal-products-list/madurampappad.jpg" alt="madurampappad" title="madurampappad" class="img-thumbnail" /></a> </td>
          <td class="text-left"><a href="#">Maduram pappad</a>             </td>
          <td class="text-right">x 1</td>
          <td class="text-right">$122.00</td>
          <td class="text-center"><button type="button"  title="Remove" class="btn btn-danger btn-xs"><i class="fa fa-times"></i></button></td>
        </tr>
                      </table>
    </li>
    <li>
      <div>
        <table class="table table-bordered">
                    <tr>
            <td class="text-right"><strong>Sub-Total</strong></td>
            <td class="text-right">$100.00</td>
          </tr>
                    <tr>
            <td class="text-right"><strong>Eco Tax (-2.00)</strong></td>
            <td class="text-right">$2.00</td>
          </tr>
                    <tr>
            <td class="text-right"><strong>VAT (20%)</strong></td>
            <td class="text-right">$20.00</td>
          </tr>
                    <tr>
            <td class="text-right"><strong>Total</strong></td>
            <td class="text-right">$122.00</td>
          </tr>
                  </table>
        <p class="text-right"><a  routerLink="/shopcart" class="btn btn-primary"><i class="fa fa-shopping-cart"></i> View Cart</a>&nbsp;&nbsp;&nbsp;<a routerLink="/checkout" class="btn btn-primary"><i class="fa fa-share"></i> Checkout</a></p>
      </div>
    </li>
      </ul> -->
</div>

          <!--Account end -->
        </div>
      </div>
    </div>
  </header>


  