import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EmailValidator, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { prductApiService } from '../services/productapi.service';
import { Register } from '../interfacelist/register';
import { emitDistinctChangesOnlyDefaultValue } from '@angular/compiler/src/core';
import Swal from 'sweetalert2'
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  register?: Register[];
  loading = false;
  submitted = false;
  error:any;
  // successAlert: boolean = false;
  // messageDisplay?: string;
  // messageData?: string;
  // warningAlert?: boolean = false;


  loginForm: FormGroup = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.minLength(10)]]
  });
  constructor(private formBuilder: FormBuilder, private router: Router, private _prductApiService: prductApiService) { }

  ngOnInit(): void {

  }
  get f() { return this.loginForm.controls; }
  onSubmit() {
        this.submitted = true;
        this.loading = true;  
                    
        this._prductApiService.postLogin(this.loginForm?.value)
          .subscribe(data => {
            if(data != '')
            {
              Swal.fire({
                text: 'Login Successfully !!!',
                icon: 'success',
                timer:2000
              }); 
              
              localStorage.setItem('token', data.token);
              localStorage.setItem('email', data.email);
              localStorage.setItem('displayName', data.displayName);
              this.router.navigate(['/home']);
              // this.successMessageDisplay();
              // console.log(this.successMessageDisplay());
              
              
            }                      
          },
          error =>{  
            if(error){       
            this.error= Swal.fire({
              text: 'Enter Valid Email and Password',
              icon: 'warning',
              timer:2000
            }); 
            console.log(this.error);
                     
          }});
     
  }
  // successMessageDisplay(){
  //   this.messageData = 'Login Success !!!'
  //   this.messageDisplay = 'Successfully Login';
  //   this.successAlert = true;
  //   setTimeout(() => {this.successAlert = false}, 2000);
  // }
  // errorMessageDisplay() {
  //   this.messageData = 'Warning !!!'
  //   this.messageDisplay = 'Enter Valid Data';
  //   this.warningAlert = true;
  //   setTimeout(() => { this.warningAlert = false }, 2000);
  // }
  // onClose() {
  //   this.successAlert = false;
  //   this.warningAlert = false;
  // }

}
