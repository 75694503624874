import { Component, OnInit } from '@angular/core';
import { Productlist } from '../interfacelist/productlist';
import { BrandType } from '../interfacelist/brandtype';
import { Router } from '@angular/router';
import { prductApiService } from '../services/productapi.service';
import {BasketService} from '../basket/basket.service';
// declare function productfilter() : any;
// declare function listgridView() : any;

@Component({
  selector: 'app-productlist',
  templateUrl: './productlist.component.html',
  styleUrls: ['./productlist.component.css']
})
export class ProductlistComponent implements OnInit {

  constructor(private router: Router,private _prductApiService: prductApiService,private BasketService:BasketService) { }
  listcomments?: any;
  brandtype: any;
  typedata : any;
  selected?: Number;
  pagesize: number = 10;
  totalCount?: string;
  p: number = 1;
  returnData: any;
  selectbrand: any;
  selecttype:any;
  adddata?: any;
  ngOnInit() {
    window.scroll(0,0);
    this._prductApiService.getComments()
      .subscribe(data => {
        this.listcomments = data['data'];
       
        
        this.totalCount = data['count'];
        this.p = data['pageIndex'];
        this.pageChange(1);
        
        
      });
      this._prductApiService.getBrandData()
      .subscribe(data => {
        this.brandtype = data;
      });
      this._prductApiService.getTypeData()
      .subscribe(data => {
        this.typedata = data;
      });
      
  }
  
  brandvalue(brandid: any)
  {
    window.scroll(0,0);
    this.p = 1;
    this.selectbrand = brandid.target.value;
    if(this.selectbrand && brandid.target.checked){
    this._prductApiService.getBrandDataByID(this.pagesize, this.p, +this.selectbrand)
        .subscribe(data => {
          this.returnData = data;
          this.listcomments = this.returnData['data'];    
        });
      }
  }
  typevalue(typeid: any)
  {
    window.scroll(0,0);
    this.p = 1;
    this.selecttype = typeid.target.value;
   if(this.selectbrand)
    {
      if(this.selecttype && typeid.target.checked){
      this._prductApiService.getBrandTypeDataByID(this.pagesize, this.p,+this.selecttype,this.selectbrand)
      .subscribe(data => {
        this.returnData = data;
        this.listcomments = this.returnData['data'];    
      });
    }
    }
    else if(this.selecttype && typeid.target.checked){
    this._prductApiService.getTypeDataByID(this.pagesize, this.p,+this.selecttype)
        .subscribe(data => {
          this.returnData = data;
          this.listcomments = this.returnData['data'];    
        });
      }
    else
      {
          console.log("Empty");
          
      }
  }
  sort(val: any) {
    window.scroll(0,0);
    this.p = 1;// event will give you full breif of action
    this.selected = val.target.value;
    if (this.selected == 1) {
      this._prductApiService.getSortPagenationData(this.pagesize, this.p, 'priceAsc')
        .subscribe(data => {
          this.returnData = data;
          this.listcomments = this.returnData['data'];
          this.totalCount = this.returnData['count'];
          
        });
    }
    else if (this.selected == 2) {
      
      this._prductApiService.getSortPagenationData(this.pagesize, this.p, 'priceDesc')
        .subscribe(data => {
          this.returnData = data;
          this.listcomments = this.returnData['data'];
          this.totalCount = this.returnData['count'];
        });
    }
    else {
      this._prductApiService.getComments()
        .subscribe(data => {
          this.listcomments = data['data'];
          this.totalCount = data['count'];
        });
    }
  }
  pageChange(event: number) { 
    window.scroll(0,0);  
    this.p = event;
    if (this.selected == 1) {
      this._prductApiService.getSortPagenationData(this.pagesize, event, 'priceAsc').subscribe(data => {
        this.returnData = data;
        this.listcomments = this.returnData['data'];
        this.totalCount = this.returnData['count'];
      });
    }
    else if (this.selected == 2) {
      this._prductApiService.getSortPagenationData(this.pagesize, event, 'priceDesc').subscribe(data => {
        this.returnData = data;
        this.listcomments = this.returnData['data'];
        this.totalCount = this.returnData['count'];
      });
    }
    else if(this.selectbrand){
      this._prductApiService.getBrandDataByID(this.pagesize, event, this.selectbrand).subscribe(data => {
        this.returnData = data;
        this.listcomments = this.returnData['data'];
        this.totalCount = this.returnData['count'];
      });
    }
    else if(this.selecttype){
      this._prductApiService.getTypeDataByID(this.pagesize, event, this.selecttype).subscribe(data => {
        this.returnData = data;
        this.listcomments = this.returnData['data'];
        this.totalCount = this.returnData['count'];
      });
    }
    else {
      this._prductApiService.getPagenationData(this.pagesize, event).subscribe(data => {
        this.returnData = data;
        
        
        this.listcomments = this.returnData['data'];
        this.totalCount = this.returnData['count'];
      });
    }
  }
  addItemToBasket(index: number){  
      this.adddata = this.listcomments[index];      
      this.BasketService.addItemToBasket(this.adddata);  
  }
}
