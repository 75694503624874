import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BasketService } from '../basket/basket.service';
import { IBasket } from '../interfacelist/basket';
import { prductApiService } from '../services/productapi.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  name:any;
  loading = false;
  basket$?: Observable<IBasket>;
  menu?: any;
  details?:any;
  logo?:any;
  constructor(private router: Router,private basketService: BasketService,private _prductApiService: prductApiService) { }

  ngOnInit(): void {
    this.name=localStorage.getItem("displayName");
    this.basket$ =this.basketService.basket$;
    this._prductApiService.addressDetails().subscribe(data=>{
    this.details = data;
    
    });
    this._prductApiService.menuDetails().subscribe(data=>{
      this.menu = data;
    });
    this._prductApiService.logo().subscribe(data =>{
      this.logo = data;
    });
  }
  logout()
  {
    this.loading = true;  
    localStorage.removeItem("displayName");
    localStorage.removeItem("email");
    localStorage.removeItem("token");
    this.router.navigate(['/login']);
    
  }

}
