<app-header></app-header>
<div id="product-product" class="container common-shed" >
  <div class="row">
    <div id="content" class="col-sm-12" *ngFor = "let list of singleproductlist; let indexOfelement=index;">
      <ul class="breadcrumb">
        <li><a href="#"><i class="fa fa-home"></i></a></li>
        <li><a href="#">{{list.name}}</a></li>
      </ul>
      <div class="">
        <div class="col-md-12 col-sm-12 inspire-form-bg">
          <div class="col-md-5 col-sm-6 proimg">
            <ul class="thumbnails">
              <li><a class="thumbnail" [href]="list.pictureUrl" title="Coconut">
                  <img [attr.data-zoom-image]="list.pictureUrl" [src]="list.pictureUrl"
                    id="inspzoom" class="img-responsive center-block" alt="image">
                </a>
              </li>
              <!-- <div class="row">
                <li id="additional" class="owl-carousel">
                  <a data-zoom-image="image/kayal-products/Coconut.jpg" data-image="image/kayal-products/Coconut.jpg"
                    href="image/kayal-products/Coconut.jpg">
                    <img src="assets/images/kayal-products/Coconut.jpg" class="img-responsive center-block col-xs-12"
                      alt="additional image">
                  </a>
                  <a data-zoom-image="image/kayal-products/Coconut.jpg" data-image="image/kayal-products/Coconut.jpg"
                    href="image/kayal-products/Coconut.jpg">
                    <img src="assets/images/kayal-products/Coconut.jpg" class="img-responsive center-block col-xs-12"
                      alt="additional image">
                  </a>
                  <a data-zoom-image="image/kayal-products/Coconut.jpg" data-image="image/kayal-products/Coconut.jpg"
                    href="image/kayal-products/Coconut.jpg">
                    <img src="assets/images/kayal-products/Coconut.jpg" class="img-responsive center-block col-xs-12"
                      alt="additional image">
                  </a>
                  <a data-zoom-image="image/kayal-products/Coconut.jpg" data-image="image/kayal-products/Coconut.jpg"
                    href="image/kayal-products/Coconut.jpg">
                    <img src="assets/images/kayal-products/Coconut.jpg" class="img-responsive center-block col-xs-12"
                      alt="additional image">
                  </a>
                  <a data-zoom-image="image/kayal-products/Coconut.jpg" data-image="image/kayal-products/Coconut.jpg"
                    href="image/kayal-products/Coconut.jpg">
                    <img src="assets/images/kayal-products/Coconut.jpg" class="img-responsive center-block col-xs-12"
                      alt="additional image">
                  </a>
                </li>
              </div> -->
            </ul>
          </div>
          <div class="col-md-7 col-sm-6 product-right">
            <!--product Details Start -->
            <div class="col-md-6">
              <h1>{{list.name}}</h1>
              <hr class="prosp">
              <ul class="list-unstyled">
                <li><span class="text-prodecor">Brand:</span><a href="#" class="textdeb">{{list.productBrand}}</a></li>
                <!-- <li><span class="text-prodecor">Product Code:</span> Product 15</li>
                <li><span class="text-prodecor">Reward Points:</span> 100</li> -->
              </ul>
              <div id="product">
                <hr class="prosp">
                <h3>Available Options</h3>
                <!-- <div class="form-group required ">
                  <label class="control-label text-prodecorop">Pack Size</label>
                  <div id="input-option218" class="custom-radio">
                    <div class="radio">
                      <label>
                        <input type="radio" name="option[218]" value="5">
                        300 ml Oil
                        (+$12.00)
                      </label>
                    </div>
                    <div class="radio">
                      <label>
                        <input type="radio" name="option[218]" value="6">
                        600 ml Oil
                        (+$24.00)
                      </label>
                    </div>
                    <div class="radio">
                      <label>
                        <input type="radio" name="option[218]" value="7">
                        1 ltr Oil
                        (+$36.00)
                      </label>
                    </div>
                  </div>
                </div> -->


                <hr class="prosp">
                <div class="form-group">
                  <div class="row">
                    <div class="col-sm-2 col-md-2 col-xs-2 op-box qtlabel">
                      <label class="control-label text-prodecorop" for="input-quantity">Qty</label>
                    </div>
                    <div class="col-md-10 col-sm-10 col-xs-10 op-box qty-plus-minus">
                      <button type="button" (click)="decrement()" class="form-control pull-left btn-number btnminus" 
                      data-type="minus" data-field="quantity">
                        <span class="glyphicon glyphicon-minus"></span>
                      </button>
                      <input id="input-quantity" type="text" name="quantity" size="2" id="input-quantity"
                        class="form-control input-number pull-left" [value]="counter">
                      <input type="hidden" name="product_id" value="42">
                      <button type="button" class="form-control pull-left btn-number btnplus" data-type="plus"
                        data-field="quantity" (click)="increment()">
                        <span class="glyphicon glyphicon-plus"></span>
                      </button>
                    </div>
                  </div>
                  <hr class="prosp">
                  <button type="button" id="button-carts" data-loading-text="Loading..."
                    class="btn add-to-cart btn-primary" (click)="cartadd()">Add to Cart</button>
                  <!-- <button type="button" data-toggle="tooltip" title="Add to Wish List"
                    class="btn add-to-cart btn-primary"><i class="fa fa-heart"></i></button>
                  <button type="button" data-toggle="tooltip" title="Compare this Product"
                    class="btn add-to-cart btn-primary"><i class="fa fa-compress"></i></button> -->
                  <hr class="prosp">
                </div>
                <!-- Quantity option end -->
                <div class="alert alert-info palert"><i class="fa fa-info-circle"></i> This product has a minimum
                  quantity of 2</div>
              </div>
            </div>
            <!--product Details End -->
            <div class="col-md-6 p-p-price-detail">
              <div>Availability: <span class="in-stock">In Stock</span></div>
              <ul class="list-unstyled price-desc">
                <!-- <li><span style="text-decoration: line-through;">$122.00</span></li> -->
                <li class="text-prodecor-bold">
                  <h2 class="pro-price">Price: Rs. {{list.price}}</h2>
                </li>
                <!-- <li>Ex Tax: $90.00</li>

                <li>Price in reward points: 400</li>
                <li>
                  <hr class="prosp">
                </li>
                <li>10 or more $107.60</li>
                <li>20 or more $94.40</li>
                <li>30 or more $81.20</li> -->
              </ul>
              <div class="rating">
                <ul class="list-inline">
                  <li class="prodrate"> <span class="fa fa-stack"><i class="fa fa-star-o fa-stack-1x"></i></span>
                    <span class="fa fa-stack"><i class="fa fa-star-o fa-stack-1x"></i></span> <span
                      class="fa fa-stack"><i class="fa fa-star-o fa-stack-1x"></i></span> <span class="fa fa-stack"><i
                        class="fa fa-star-o fa-stack-1x"></i></span> <span class="fa fa-stack"><i
                        class="fa fa-star-o fa-stack-1x"></i></span>
                  </li>
                  <li class="proreviewre">
                    <a href="" onclick="$('a[href=\'#tab-review\']').trigger('click'); return false;">0 reviews</a>
                  </li>
                  <li class="prowritere">
                    <a href="#rt" onclick="$('a[href=\'#tab-review\']').trigger('click'); return false;">Write a
                      review</a>
                  </li>
                </ul>
                <hr class="prosp">
                <!-- AddToAny BEGIN -->
                <div class="a2a_kit a2a_kit_size_32 a2a_default_style">
                  <a class="a2a_button_facebook"></a>
                  <a class="a2a_button_twitter"></a>
                  <a class="a2a_button_google_plus"></a>
                  <a class="a2a_button_pinterest"></a>
                  <a class="a2a_button_linkedin"></a>
                  <a class="a2a_dd" href="../../share.html"></a>
                </div>
                <script async="" src="../../menu/page.js"></script>
                <!-- AddToAny END -->
              </div>

            </div>


          </div>
        </div>
      </div>
      <!-- <div class="propage-tab">
        <ul class="nav nav-tabs">

          <li class="active"><a href="#tab-specification" data-toggle="tab">Information about Kayal 100% Pure Coconut
              Oil</a></li>

          <li><a href="#tab-description" data-toggle="tab">Description</a></li>
          <li><a href="#tab-review" data-toggle="tab">Reviews (0)</a></li>
        </ul>
        <div class="tab-content">
          <div class="tab-pane active" id="tab-specification">
           
          </div>
          <div class="tab-pane" id="tab-description">
            <p>{{list.description}}</p>
          </div>
          <div class="tab-pane" id="tab-review">
            <form class="form-horizontal" id="form-review">
              <div id="review"></div>
              <h2 class="heading co-heading">Write a review</h2>
              <div class="form-group required">
                <div class="col-sm-12">
                  <label class="control-label" for="input-name">Your Name</label>
                  <input type="text" name="name" value="" id="input-name" class="form-control">
                </div>
              </div>
              <div class="form-group required">
                <div class="col-sm-12">
                  <label class="control-label" for="input-review">Your Review</label>
                  <textarea name="text" rows="5" id="input-review" class="form-control"></textarea>
                  <div class="help-block"><span class="text-danger">Note:</span> HTML is not translated!</div>
                </div>
              </div>
              <div class="form-group required">
                <div class="col-sm-12">
                  <label class="control-label rat">Rating</label>
                  &nbsp;&nbsp;&nbsp; Bad&nbsp;
                  <input type="radio" name="rating" value="1">
                  &nbsp;
                  <input type="radio" name="rating" value="2">
                  &nbsp;
                  <input type="radio" name="rating" value="3">
                  &nbsp;
                  <input type="radio" name="rating" value="4">
                  &nbsp;
                  <input type="radio" name="rating" value="5">
                  &nbsp;Good
                </div>
              </div>

              <div class="buttons clearfix">
                <div class="pull-right">
                  <button type="button" id="button-review" data-loading-text="Loading..."
                    class="btn btn-primary">Continue</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div> -->
      <!-- relatedproduct -->
      <!-- <div class="pro-bg">
        <h2 class="allhading">Related Products</h2>
        <div class="pro-nepr">
          <div id="related" class="owl-carousel owl-theme">
            <div class="col-xs-12 col-sm-12 product-layout">
              <div class="product-thumb transition">
                <div class="image"><a href="#"><img src="assets/images/kayal-products-list/indigatepappad.jpg"
                      alt="Mosambi, 6 pcs 800 gm - 1 kg " title="Mosambi, 6 pcs 800 gm - 1 kg "
                      class="img-responsive center-block"></a>
                </div>
                <div class="caption text-center">
                  <h4><a href="#">India Gate Pappad
                    </a></h4>
                  <div class="rating"> <span class="fa fa-stack"><i class="fa fa-star-o fa-stack-2x"></i></span>
                    <span class="fa fa-stack"><i class="fa fa-star-o fa-stack-2x"></i></span>
                    <span class="fa fa-stack"><i class="fa fa-star-o fa-stack-2x"></i></span>
                    <span class="fa fa-stack"><i class="fa fa-star-o fa-stack-2x"></i></span>
                    <span class="fa fa-stack"><i class="fa fa-star-o fa-stack-2x"></i></span>
                  </div>



                  <p class="price">$337.99</p>
                  <div class="button-group">
                    <button class="acart" data-toggle="tooltip" title="Add to Cart" type="button"><svg width="16px"
                        height="16px">
                        <use xlink:href="#addcart"></use>
                      </svg></button>
                    <button type="button" data-toggle="tooltip" title="Add to Wish List"><svg width="16px"
                        height="16px">
                        <use xlink:href="#addwish"></use>
                      </svg></button>
                    <button type="button" data-toggle="tooltip" title="Compare this Product"><svg width="16px"
                        height="16px">
                        <use xlink:href="#addcompare"></use>
                      </svg></button>
                    <div class="bquickv" data-toggle="tooltip" title="Quick View"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 product-layout">
              <div class="product-thumb transition">
                <div class="image"><a href="#"><img src="assets/images/kayal-products-list/madurampappad.jpg"
                      alt="maduram pappad" title="Maduram Pappad" class="img-responsive center-block"></a>
                </div>
                <div class="caption text-center">
                  <h4><a href="#">Maduram Pappad</a></h4>
                  <div class="rating">
                    <span class="fa fa-stack">
                      <i class="fa fa-star fa-stack-2x"></i><i class="fa fa-star-o fa-stack-2x"></i>
                    </span>
                    <span class="fa fa-stack">
                      <i class="fa fa-star fa-stack-2x"></i><i class="fa fa-star-o fa-stack-2x"></i>
                    </span>
                    <span class="fa fa-stack">
                      <i class="fa fa-star fa-stack-2x"></i><i class="fa fa-star-o fa-stack-2x"></i>
                    </span>
                    <span class="fa fa-stack">
                      <i class="fa fa-star fa-stack-2x"></i><i class="fa fa-star-o fa-stack-2x"></i>
                    </span>
                    <span class="fa fa-stack">
                      <i class="fa fa-star-o fa-stack-2x"></i>
                    </span>
                  </div>


                  <p class="price-new"><span class="price-new">$98.00</span> <span class="price-old">$122.00</span>
                  </p>
                  <div class="button-group">
                    <button class="acart" data-toggle="tooltip" title="Add to Cart" type="button" (click)="cartadd()"><svg width="16px"
                        height="16px">
                        <use xlink:href="#addcart"></use>
                      </svg></button>
                  
                    <div class="bquickv" data-toggle="tooltip" title="Quick View"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 product-layout">
              <div class="product-thumb transition">
                <div class="image"><a href="#"><img src="assets/images/kayal-products-list/rooster.jpg" alt="Rooster"
                      title="Rooster" class="img-responsive center-block"></a>
                </div>
                <div class="caption text-center">
                  <h4><a routerLink="/">Rooster</a></h4>
                  <div class="rating"> <span class="fa fa-stack"><i class="fa fa-star-o fa-stack-2x"></i></span>
                    <span class="fa fa-stack"><i class="fa fa-star-o fa-stack-2x"></i></span>
                    <span class="fa fa-stack"><i class="fa fa-star-o fa-stack-2x"></i></span>
                    <span class="fa fa-stack"><i class="fa fa-star-o fa-stack-2x"></i></span>
                    <span class="fa fa-stack"><i class="fa fa-star-o fa-stack-2x"></i></span>
                  </div>



                  <p class="price">$98.00</p>
                  <div class="button-group">
                    <button class="acart" data-toggle="tooltip" title="Add to Cart" type="button"><svg width="16px"
                        height="16px">
                        <use xlink:href="#addcart"></use>
                      </svg></button>
                    <button type="button" data-toggle="tooltip" title="Add to Wish List"><svg width="16px"
                        height="16px">
                        <use xlink:href="#addwish"></use>
                      </svg></button>
                    <button type="button" data-toggle="tooltip" title="Compare this Product"><svg width="16px"
                        height="16px">
                        <use xlink:href="#addcompare"></use>
                      </svg></button>
                    <div class="bquickv" data-toggle="tooltip" title="Quick View"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 product-layout">
              <div class="product-thumb transition">
                <div class="image"><a href="#"><img src="assets/images/kayal-products-list/csiappalam.jpg"
                      alt="CSI Appalam" title="CSI Appalam" class="img-responsive center-block"></a>
                </div>
                <div class="caption text-center">
                  <h4><a href="#">CSI Appalam</a></h4>
                  <div class="rating"> <span class="fa fa-stack"><i class="fa fa-star-o fa-stack-2x"></i></span>
                    <span class="fa fa-stack"><i class="fa fa-star-o fa-stack-2x"></i></span>
                    <span class="fa fa-stack"><i class="fa fa-star-o fa-stack-2x"></i></span>
                    <span class="fa fa-stack"><i class="fa fa-star-o fa-stack-2x"></i></span>
                    <span class="fa fa-stack"><i class="fa fa-star-o fa-stack-2x"></i></span>
                  </div>



                  <p class="price">$122.00</p>
                  <div class="button-group">
                    <button class="acart" data-toggle="tooltip" title="Add to Cart" type="button"><svg width="16px"
                        height="16px">
                        <use xlink:href="#addcart"></use>
                      </svg></button>
                    <button type="button" data-toggle="tooltip" title="Add to Wish List"><svg width="16px"
                        height="16px">
                        <use xlink:href="#addwish"></use>
                      </svg></button>
                    <button type="button" data-toggle="tooltip" title="Compare this Product"><svg width="16px"
                        height="16px">
                        <use xlink:href="#addcompare"></use>
                      </svg></button>
                    <div class="bquickv" data-toggle="tooltip" title="Quick View"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 product-layout">
              <div class="product-thumb transition">
                <div class="image"><a href="#"><img src="assets/images/kayal-products-list/indigatepappad.jpg"
                      alt="India Gate Papad" title="India Gate Papad" class="img-responsive center-block"></a>
                </div>
                <div class="caption text-center">
                  <h4><a href="#">India Gate Papad </a></h4>
                  <div class="rating"> <span class="fa fa-stack"><i class="fa fa-star-o fa-stack-2x"></i></span>
                    <span class="fa fa-stack"><i class="fa fa-star-o fa-stack-2x"></i></span>
                    <span class="fa fa-stack"><i class="fa fa-star-o fa-stack-2x"></i></span>
                    <span class="fa fa-stack"><i class="fa fa-star-o fa-stack-2x"></i></span>
                    <span class="fa fa-stack"><i class="fa fa-star-o fa-stack-2x"></i></span>
                  </div>



                  <p class="price">$242.00</p>
                  <div class="button-group">
                    <button class="acart" data-toggle="tooltip" title="Add to Cart" type="button"><svg width="16px"
                        height="16px">
                        <use xlink:href="#addcart"></use>
                      </svg></button>
                    <button type="button" data-toggle="tooltip" title="Add to Wish List"><svg width="16px"
                        height="16px">
                        <use xlink:href="#addwish"></use>
                      </svg></button>
                    <button type="button" data-toggle="tooltip" title="Compare this Product"><svg width="16px"
                        height="16px">
                        <use xlink:href="#addcompare"></use>
                      </svg></button>
                    <div class="bquickv" data-toggle="tooltip" title="Quick View"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 product-layout">
              <div class="product-thumb transition">
                <div class="image"><a href="#"><img src="assets/images/kayal-products/Sesame-Oil.jpg" alt="Sesame-Oil"
                      title="Sesame-Oil" class="img-responsive center-block"></a>
                </div>
                <div class="caption text-center">
                  <h4><a routerLink="/">Sesame-Oil
                    </a></h4>
                  <div class="rating">
                    <span class="fa fa-stack">
                      <i class="fa fa-star fa-stack-2x"></i><i class="fa fa-star-o fa-stack-2x"></i>
                    </span>
                    <span class="fa fa-stack">
                      <i class="fa fa-star fa-stack-2x"></i><i class="fa fa-star-o fa-stack-2x"></i>
                    </span>
                    <span class="fa fa-stack">
                      <i class="fa fa-star fa-stack-2x"></i><i class="fa fa-star-o fa-stack-2x"></i>
                    </span>
                    <span class="fa fa-stack">
                      <i class="fa fa-star-o fa-stack-2x"></i>
                    </span>
                    <span class="fa fa-stack">
                      <i class="fa fa-star-o fa-stack-2x"></i>
                    </span>
                  </div>


                  <p class="price">$122.00</p>
                  <div class="button-group">
                    <button class="acart" data-toggle="tooltip" title="Add to Cart" type="button"><svg width="16px"
                        height="16px">
                        <use xlink:href="#addcart"></use>
                      </svg></button>
                    <button type="button" data-toggle="tooltip" title="Add to Wish List"><svg width="16px"
                        height="16px">
                        <use xlink:href="#addwish"></use>
                      </svg></button>
                    <button type="button" data-toggle="tooltip" title="Compare this Product"><svg width="16px"
                        height="16px">
                        <use xlink:href="#addcompare"></use>
                      </svg></button>
                    <div class="bquickv" data-toggle="tooltip" title="Quick View"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 product-layout">
              <div class="product-thumb transition">
                <div class="image"><a href="#"><img src="assets/images/kayal-products/Poonga-01.jpg" alt="Poonga-01"
                      title="Poonga-01" class="img-responsive center-block"></a>
                </div>
                <div class="caption text-center">
                  <h4><a routerLink="/">Poonga-01</a></h4>
                  <div class="rating">
                    <span class="fa fa-stack">
                      <i class="fa fa-star fa-stack-2x"></i><i class="fa fa-star-o fa-stack-2x"></i>
                    </span>
                    <span class="fa fa-stack">
                      <i class="fa fa-star fa-stack-2x"></i><i class="fa fa-star-o fa-stack-2x"></i>
                    </span>
                    <span class="fa fa-stack">
                      <i class="fa fa-star fa-stack-2x"></i><i class="fa fa-star-o fa-stack-2x"></i>
                    </span>
                    <span class="fa fa-stack">
                      <i class="fa fa-star fa-stack-2x"></i><i class="fa fa-star-o fa-stack-2x"></i>
                    </span>
                    <span class="fa fa-stack">
                      <i class="fa fa-star-o fa-stack-2x"></i>
                    </span>
                  </div>


                  <p class="price">$122.00</p>
                  <div class="button-group">
                    <button class="acart" data-toggle="tooltip" title="Add to Cart" type="button"><svg width="16px"
                        height="16px">
                        <use xlink:href="#addcart"></use>
                      </svg></button>
                    <button type="button" data-toggle="tooltip" title="Add to Wish List"><svg width="16px"
                        height="16px">
                        <use xlink:href="#addwish"></use>
                      </svg></button>
                    <button type="button" data-toggle="tooltip" title="Compare this Product"><svg width="16px"
                        height="16px">
                        <use xlink:href="#addcompare"></use>
                      </svg></button>
                    <div class="bquickv" data-toggle="tooltip" title="Quick View"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 product-layout">
              <div class="product-thumb transition">
                <div class="image"><a href="#"><img src="assets/images/kayal-products/maduram-pappad.jpg"
                      alt="Maduram Papad " title="Maduram Papad, Regular, 4 pcs "
                      class="img-responsive center-block"></a>
                </div>
                <div class="caption text-center">
                  <h4><a href="#">Maduram Papad, Regular, 4 pcs </a></h4>
                  <div class="rating"> <span class="fa fa-stack"><i class="fa fa-star-o fa-stack-2x"></i></span>
                    <span class="fa fa-stack"><i class="fa fa-star-o fa-stack-2x"></i></span>
                    <span class="fa fa-stack"><i class="fa fa-star-o fa-stack-2x"></i></span>
                    <span class="fa fa-stack"><i class="fa fa-star-o fa-stack-2x"></i></span>
                    <span class="fa fa-stack"><i class="fa fa-star-o fa-stack-2x"></i></span>
                  </div>



                  <p class="price">$123.20</p>
                  <div class="button-group">
                    <button class="acart" data-toggle="tooltip" title="Add to Cart" type="button"><svg width="16px"
                        height="16px">
                        <use xlink:href="#addcart"></use>
                      </svg></button>
                    <button type="button" data-toggle="tooltip" title="Add to Wish List"><svg width="16px"
                        height="16px">
                        <use xlink:href="#addwish"></use>
                      </svg></button>
                    <button type="button" data-toggle="tooltip" title="Compare this Product"><svg width="16px"
                        height="16px">
                        <use xlink:href="#addcompare"></use>
                      </svg></button>
                    <div class="bquickv" data-toggle="tooltip" title="Quick View"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 product-layout">
              <div class="product-thumb transition">
                <div class="image"><a href="#"><img src="assets/images/kayal-products/Maduram.jpg" alt="Maduram"
                      title="Maduram" class="img-responsive center-block"></a>
                </div>
                <div class="caption text-center">
                  <h4><a href="#">Maduram</a></h4>
                  <div class="rating"> <span class="fa fa-stack"><i class="fa fa-star-o fa-stack-2x"></i></span>
                    <span class="fa fa-stack"><i class="fa fa-star-o fa-stack-2x"></i></span>
                    <span class="fa fa-stack"><i class="fa fa-star-o fa-stack-2x"></i></span>
                    <span class="fa fa-stack"><i class="fa fa-star-o fa-stack-2x"></i></span>
                    <span class="fa fa-stack"><i class="fa fa-star-o fa-stack-2x"></i></span>
                  </div>

                  <p class="price-new"><span class="price-new">$122.00</span> <span class="price-old">$1,202.00</span>
                  </p>
                  <div class="button-group">
                    <button class="acart" data-toggle="tooltip" title="Add to Cart" type="button"><svg width="16px"
                        height="16px">
                        <use xlink:href="#addcart"></use>
                      </svg></button>
                    <button type="button" data-toggle="tooltip" title="Add to Wish List"><svg width="16px"
                        height="16px">
                        <use xlink:href="#addwish"></use>
                      </svg></button>
                    <button type="button" data-toggle="tooltip" title="Compare this Product"><svg width="16px"
                        height="16px">
                        <use xlink:href="#addcompare"></use>
                      </svg></button>
                    <div class="bquickv" data-toggle="tooltip" title="Quick View"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div> -->

    </div>
  </div>
</div>
<app-footer></app-footer>