<div class="col-sm-12" style="margin-bottom: 30PX;">
<div class="row justify-content-center card">
    <div class="col-sm-3"></div>
    <div class="col-sm-6">
      <div class="headerPay">
        <span>Payment Options</span></div>
        <div class="row">
<div class="form-check col-sm-6">
    <input class="form-check-input mr-2" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value="cash" (change)="onOptionChange($event)">
    <label class="form-check-label " for="flexRadioDefault1 ml-2">
      Cash on delivery 
    </label>
  </div>
  <div class="form-check col-sm-6">
    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" value="card" (change)="onOptionChange($event)">
    <label class="form-check-label" for="flexRadioDefault2">
      Card
    </label>
  </div>
  </div>
  <div [hidden]="isCardPay">
  <div class="col-sm-12">
  <div class="row mt-2">
    <div class="form-group" [formGroup]="paymentForm">
      <label>Card Name</label>
        <input formControlName="nameOnCard" class="col-sm-12 form-control">
    </div>
  </div>
</div>
<div class="col-sm-12 setPadding">
    <div class="row">
    <div class="form-group col-sm-6 ">
        <div #cardNumber class="form-control py-3"></div>
        <ng-container >
            <span class="cardFont">{{cardErrors}}</span>
        </ng-container>
    </div>
    <div class="form-group col-sm-3 removePadding">
        <div #cardExpiry class="form-control py-3"></div>
    </div>
    <div class="form-group col-sm-3">
        <div #cardCvc class="form-control py-3"></div>
    </div>
  </div>
</div>
</div>
</div>
<div class="mt-4">
</div>
</div>
</div>
<div class="pull-right">
<button [disabled]="isCashPay" class=" btn btn-primary " (click)="submitOrder()">Submit Order</button>
</div>