import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutusComponent } from './aboutus/aboutus.component';

import { BasketComponent } from './basket/basket.component';
import { CheckoutSuccessComponent } from './checkout/checkout-success/checkout-success.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { ContactusComponent } from './contactus/contactus.component';
import { AuthGuard } from './guard/auth.guard';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { ProductComponent } from './product/product.component';
import { ProductlistComponent } from './productlist/productlist.component';
import { RegisterComponent } from './register/register.component';
import { ShopcartComponent } from './shopcart/shopcart.component';

const routes: Routes = [
  {path: '', redirectTo: '/home', pathMatch: 'full'}, 
  { path: 'home', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'product/:id', component: ProductComponent },
  { path: 'productlist', component: ProductlistComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'shopcart', component: ShopcartComponent },
  { path: 'basket', component: BasketComponent },
  { path: 'about', component: AboutusComponent},
  { path: 'contact', component: ContactusComponent},
  { path: 'success', component:CheckoutSuccessComponent},
  { path: 'checkout', component: CheckoutComponent, canActivate: [AuthGuard]}
]; 
  

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
