<app-header></app-header>
<div id="product-category" class="container common-shed">
    <ul class="breadcrumb">
      <li><a routerLink="/home"><i class="fa fa-home"></i></a></li>
      <li><a href="#">Papad</a></li>
      <li><a href="#">Papad Types</a></li>
    </ul>
    <div class="row">

      <aside id="column-left" class="col-md-3 col-sm-4 col-xs-12">
        <!-- <div class="list-group hidden-xs">

          <a href="#" class="list-group-item active">Papad
            (7)</a>
          <a href="#"
            class="list-group-item active">&nbsp;&nbsp;&nbsp;- Papad Types (7)</a>

          <a href="#" class="list-group-item">Edible Oil (15)</a>
          <a href="#" class="list-group-item">Pulses & Nuts (16)</a>
          <a href="#" class="list-group-item">Ready Mix Batter (16)</a>
          <a href="#" class="list-group-item">Spices (21)</a>
          <a href="#" class="list-group-item">Pooja Products (4)</a>
        </div> -->

        <div id="tf-filter" class="panel tf-filter panel-default">
          <div class="panel-heading clearfix">
            <h4 class="panel-title pull-left">Filter</h4>
            <span data-tf-reset="all" class="tf-filter-reset pull-right hide label label-info"><i
                class="fa fa-refresh"></i> Clear all</span>
          </div>
          <div class="tf-list-filter-group">

            <div class="tf-filter-group">
              <div class="tf-filter-group-header " data-toggle="collapse" href="#tf-filter-panel0">
                <span class="tf-filter-group-title">Sort By</span>
                <span class="pull-right"><i class="fa fa-caret-up"></i></span>
              </div>
              <div id="tf-filter-panel0" class="collapse in">
                <a data-tf-reset="check" class=" tf-filter-reset hide"><i class="fa fa-window-close"
                    aria-hidden="true"></i> Clear</a>
                <div class="tf-filter-group-content">

                  <div class="form-check tf-filter-value">
                    <label class="form-check-label">

                      <input type="radio" name="tf_ff" value="1" (change)='sort($event)' class="form-check-input">

                    Price (Low to High)
                    </label>

                    <span class="label label-info pull-right tf-product-total">11</span>

                  </div>

                  <div class="form-check tf-filter-value">
                    <label class="form-check-label">

                      <input type="radio" name="tf_ff" value="2" (change)='sort($event)' class="form-check-input">

                      Price (High to Low)
                    </label>
                    <span class="label label-info pull-right tf-product-total">9</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="tf-filter-group" *ngIf = brandtype>
              <div class="tf-filter-group-header " data-toggle="collapse" href="#tf-filter-panel1">
                <span class="tf-filter-group-title">Brand</span>
                <span class="pull-right"><i class="fa fa-caret-up"></i></span>
              </div>
              <div id="tf-filter-panel1" class="collapse in" *ngFor = "let brand of brandtype">
                <a data-tf-reset="check" class=" tf-filter-reset hide"><i class="fa fa-window-close"
                    aria-hidden="true"></i> Clear</a>
                <div class="tf-filter-group-content">

                  <div class="form-check tf-filter-value">
                    <label class="form-check-label">
                      <input type="checkbox" name="tf_ff" ng-model="item.isSelected" [value]="brand.id" (change)='brandvalue($event)' class="form-check-input">
                      {{brand?.name}}
                    </label>
                    <!-- <span class="label label-info pull-right tf-product-total">13</span> -->
                  </div>               
                </div>
              </div>
            </div>

            <div class="tf-filter-group" *ngIf = typedata>
              <div class="tf-filter-group-header " data-toggle="collapse" href="#tf-filter-panel1">
                <span class="tf-filter-group-title">TYPES</span>
                <span class="pull-right"><i class="fa fa-caret-up"></i></span>
              </div>
              <div id="tf-filter-panel1" class="collapse in" *ngFor = "let type of typedata">
                <a data-tf-reset="check" class=" tf-filter-reset hide"><i class="fa fa-window-close"
                    aria-hidden="true"></i> Clear</a>
                <div class="tf-filter-group-content">

                  <div class="form-check tf-filter-value">
                    <label class="form-check-label">
                      <input type="checkbox" name="tf_ff" ng-model="item.isSelected" [value]="type.id" (change)='typevalue($event)' class="form-check-input">
                      {{type?.name}}
                    </label>
                    <!-- <span class="label label-info pull-right tf-product-total">13</span> -->
                  </div>               
                </div>
              </div>
            </div>

            <!-- <div class="tf-filter-group">
              <div class="tf-filter-group-header " data-toggle="collapse" href="#tf-filter-panel2">
                <span class="tf-filter-group-title">Price</span>
                <span class="pull-right"><i class="fa fa-caret-up"></i></span>
              </div>
              <div id="tf-filter-panel2" class="collapse in">
                <a data-tf-reset="price" class=" tf-filter-reset hide"><i class="fa fa-window-close"
                    aria-hidden="true"></i> Clear</a>
                <div class="tf-filter-group-content">
                  <div data-role="rangeslider"></div>
                  <div class="row">
                    <div class="col-xs-6"><input type="number" class="form-control" name="tf_fp[min]" value="0" min="0"
                        max="1999"></div>
                    <div class="col-xs-6"><input type="number" class="form-control" name="tf_fp[max]" value="2000"
                        min="1" max="2000"></div>
                  </div>
                </div>
              </div>
            </div> -->


            <!-- <div class="tf-filter-group">
              <div class="tf-filter-group-header " data-toggle="collapse" href="#tf-filter-panel3">
                <span class="tf-filter-group-title">Manufacturer</span>
                <span class="pull-right"><i class="fa fa-caret-up"></i></span>
              </div>
              <div id="tf-filter-panel3" class="collapse in">
                <a data-tf-reset="check" class=" tf-filter-reset hide"><i class="fa fa-window-close"
                    aria-hidden="true"></i> Clear</a>
                <div class="tf-filter-group-content">

                  <div class="form-check tf-filter-value clearfix text">
                    <label class="form-check-label">

                      <input type="checkbox" name="tf_fm" value="8" class="form-check-input">
                      KRK
                    </label>
                    <span class="label label-info pull-right tf-product-total">50</span>

                  </div>
                </div>
              </div>
            </div> -->



            <!-- <div class="tf-filter-group">
              <div class="tf-filter-group-header " data-toggle="collapse" href="#tf-filter-panel4">
                <span class="tf-filter-group-title">Pay On Delivery</span>
                <span class="pull-right"><i class="fa fa-caret-up"></i></span>
              </div>
              <div id="tf-filter-panel4" class="collapse in">
                <a data-tf-reset="check" class=" tf-filter-reset hide"><i class="fa fa-window-close"
                    aria-hidden="true"></i> Clear</a>
                <div class="tf-filter-group-content">

                  <div class="form-check tf-filter-value">
                    <label class="form-check-label">
                      <input type="checkbox" name="tf_ff" value="8" class="form-check-input">
                      Eligible for Pay On Delivery
                    </label>
                    <span class="label label-info pull-right tf-product-total">11</span>
                  </div>
                </div>
              </div>
            </div> -->



            <!-- <div class="tf-filter-group">
              <div class="tf-filter-group-header " data-toggle="collapse" href="#tf-filter-panel5">
                <span class="tf-filter-group-title">Availability</span>
                <span class="pull-right"><i class="fa fa-caret-up"></i></span>
              </div>
              <div id="tf-filter-panel5" class="collapse in">
                <a data-tf-reset="check" class=" tf-filter-reset hide"><i class="fa fa-window-close"
                    aria-hidden="true"></i> Clear</a>
                <div class="tf-filter-group-content">
                  <div class="form-check tf-filter-value">
                    <label class="form-check-label">

                      <input type="radio" value="1" name="tf_fs" class="form-check-input">

                      In stock
                    </label>

                    <span class="label label-info pull-right tf-product-total">14</span>

                  </div>
                  <div class="form-check tf-filter-value">
                    <label class="form-check-label">

                      <input type="radio" value="0" name="tf_fs" class="form-check-input">

                      Out of stock
                    </label>

                    <span class="label label-info pull-right tf-product-total">1</span>

                  </div>
                </div>
              </div>
            </div> -->


          </div>
        </div>
        <script>
          $(function () {
            if (window.innerWidth < 767) { // Collaped all panel in small device
              $('#tf-filter .collapse.in').collapse("hide");
            }

            // Filter
            $('#tf-filter').tf_filter({
              requestURL: "index.html?route=extension/module/tf_filter/product&path=20_44&sub_category=0&_count_product&_manufacturer&_availability&_filter&target_route=product/category",
              searchEl: $('.tf-filter-group-search input'),
              ajax: false,
              delay: true,
              search_in_description: true,
              onParamChange: function (param) {
                // Show or hide reset all button
                if ($('.tf-filter-group [data-tf-reset]:not(.hide)').length) {
                  $('[data-tf-reset="all"]').removeClass('hide');
                } else {
                  $('[data-tf-reset="all"]').addClass('hide');
                }
              },
              onInputChange: function (e) {
                var filter_group = $(e.target).closest('.tf-filter-group');

                var is_input_selected = false;

                // Hide Reset for Checkbox or radio
                if (filter_group.find('input[type="checkbox"]:checked,input[type="radio"]:checked').length) {
                  is_input_selected = true;
                }

                // Hide Reset for price
                if ($(e.target).filter('[name="tf_fp[min]"],[name="tf_fp[max]"]').length) {
                  if ($('[name="tf_fp[min]"]').val() !== $('[name="tf_fp[min]"]').attr('min') || $('[name="tf_fp[max]"]').val() !== $('[name="tf_fp[max]"]').attr('max')) {
                    is_input_selected = true;
                  }
                }

                // Hide reset for text
                if ($(e.target).filter('[type="text"]').val()) {
                  is_input_selected = true;
                }

                // Hide or show reset buton
                if (is_input_selected) {
                  filter_group.find('[data-tf-reset]').removeClass('hide');
                } else {
                  filter_group.find('[data-tf-reset]').addClass('hide');
                }
              },
              onReset: function (el_reset) {
                var type = $(el_reset).data('tf-reset');

                // Reset price
                if (type === 'price' || type === 'all') {
                  price_slider.slider("values", [parseFloat(price_slider.slider("option", 'min')), parseFloat(price_slider.slider("option", 'max'))]);
                }

                // Hide reset button
                if ($(el_reset).data('tf-reset') !== 'all') {
                  $(el_reset).addClass('hide');
                } else {
                  $('[data-tf-reset]').addClass('hide');
                }
              }
            });

            // Price slider
            var price_slider = $("#tf-filter [data-role='rangeslider']").slider({
              range: true,
              min: parseFloat($('[name="tf_fp[min]"]').attr('min')),
              max: parseFloat($('[name="tf_fp[max]"]').attr('max')),
              values: [parseFloat($('[name="tf_fp[min]"]').val()), parseFloat($('[name="tf_fp[max]"]').val())],
              slide: function (event, ui) {
                $('[name="tf_fp[min]"]').val(ui.values[0]);
                $('[name="tf_fp[max]"]').val(ui.values[1]);
              },
              change: function (event, ui) {
                // Hide Reset for price
                if ($('[name="tf_fp[min]"]').val() !== $('[name="tf_fp[min]"]').attr('min') || $('[name="tf_fp[max]"]').val() !== $('[name="tf_fp[max]"]').attr('max')) {
                  $('[data-tf-reset="price"]').removeClass('hide');
                } else {
                  $('[data-tf-reset="price"]').addClass('hide');
                }

                // Trigger filter change
                $('#tf-filter').change();
              }
            });
            $('[name="tf_fp[min]"]').change(function () {
              price_slider.slider("values", 0, $(this).val());
            });
            $('[name="tf_fp[max]"]').change(function () {
              price_slider.slider("values", 1, $(this).val());
            });

            // Show reset all button if filter is selected
            if ($('.tf-filter-group [data-tf-reset]:not(.hide)').length) {
              $('[data-tf-reset="all"]').removeClass('hide');
            }
          });
        </script>
        <style>
          .tf-filter-group {
            border-bottom: 1px solid #ddd;
            background: white;
          }

          .tf-filter-group:last-child {
            border-bottom: 0px;
          }

          .tf-filter-group-header {
            /*border-bottom: 1px solid #ddd;*/
            padding: 15px;
          }

          .tf-filter-group-header.collapsed {
            border: none;
          }

          .tf-filter-group-title {
            text-transform: uppercase;
          }

          .tf-filter-group-content,
          .tf-filter-group-search {
            padding: 8px 15px;
            display: block;
          }

          .tf-filter-group-content {
            max-height: 200px;
            overflow-y: auto;
            padding-bottom: 15px;
          }

          .tf-filter-reset {
            cursor: pointer;
            margin-left: 15px;
          }

          .tf-filter-group-header.collapsed .fa-caret-up:before {
            content: "\f0d7";
          }

          .tf-filter-group-content .form-check-input {
            margin: 0 5px 0 0;
          }

          .tf-filter-group-content .form-check.both input,
          .tf-filter-group-content .form-check.image input {
            display: none;
          }

          .tf-filter-group-content .form-check.image {
            display: inline-block;
          }

          .tf-filter-group-content .form-check img {
            border: 1px solid transparent;
            padding: 1px;
            border-radius: 5px;
            cursor: pointer;
            margin-right: 5px;
          }

          .tf-filter-group-content .form-check input:checked+img {
            border-color: #2096c3;
            box-shadow: 0px 0px 5px #2096c3;
          }

          .tf-filter-group-content .form-check input:disabled+img {
            opacity: 0.5;
          }

          .tf-filter-group-search {
            position: relative;
          }

          .tf-filter-group-search .fa {
            position: absolute;
            color: #adadad;
          }

          .tf-filter-group-search input {
            width: 100%;
            border: none;
            border-bottom: 1px solid #ddd;
            padding: 0 0 0 18px;
            margin: 0;
            height: auto;
            border-radius: 0;
          }

          .tf-filter-group-search input:focus {
            border: none;
            border-bottom: 1px solid #bdbdbd;
          }

          .tf-loader {
            position: absolute;
            width: 100%;
            height: 100%;
            text-align: center;
            background: #ddd;
            opacity: 0.5;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 99;
          }

          .tf-loader>* {
            margin-top: 50%;
            font-size: 40px;
            transform: translateY(-50%);
          }

          .tf-filter-loading {
            cursor: wait;
          }

          .tf-filter-group-content .ui-slider {
            margin-bottom: 8px;
          }

          #column-left,
          #column-right {
            display: block !important;
          }
        </style>
      </aside>

      <div id="content" class="col-xs-12 col-sm-8 col-md-9 col-lg-9">

        <div class="row cate-border">
          <div class="col-md-2 col-sm-3 col-xs-3 catebt">
            <div class="btn-group-sm">
              <button type="button" id="list-view" class="btn inslistgrid" data-toggle="tooltip" title="List">
                <img src="assets/images/catalog/category/list.png">
              </button>
              <button type="button" id="grid-view" class="btn inslistgrid" data-toggle="tooltip" title="Grid">
                <img src="assets/images/catalog/category/grid.png">
              </button>
            </div>
          </div>
          <div class="col-lg-3 col-md-5 col-sm-6 col-xs-7 hidden-md hidden-sm hidden-xs ct">
            <a href="#" id="compare-total" class="btn btn-link">Product Compare
              (0)</a>
          </div>
          <div class="col-lg-4 col-md-5 col-xs-5 col-sm-5 sorting">
            <div class="input-group input-group-sm">
              <label class="input-group-addon" for="input-sort">Sort By:</label>
              <select id="input-sort" class="form-control" (change)='sort($event)'>
                <option
                  value="0"
                  selected="selected">Default</option>
                <option
                  value="1">
                  Price (Low &gt; High)</option>
                <option
                  value="2">
                  Price (High &gt; Low)</option>
              </select>
            </div>
          </div>
          <div class="col-lg-3 col-md-5 col-xs-4 col-sm-4 sorting">
            <div class="input-group input-group-sm">
              <label class="input-group-addon" for="input-limit">Show:</label>
              <select id="input-limit" class="form-control" onchange="location = this.value;">
                <option
                  value="#"
                  selected="selected">20</option>
                <option
                  value="#">
                  25</option>
                <option
                  value="#">
                  50</option>
                <option
                  value="#">
                  75</option>
                <option
                  value="#">
                  100</option>
              </select>
            </div>
          </div>
        </div>
        <div *ngIf = listcomments>
        <div class="row catebg">
          <div class="product-layout product-list col-xs-12" *ngFor = "let list of listcomments | paginate: { itemsPerPage: 10, currentPage: p,totalItems: totalCount };let indexOfelement=index;">
            <div class="product-thumb transition">
              <div class="image"><a routerLink="/product/{{ list.id }}" href="#"><img
                    [src]="list.pictureUrl" alt="CSI Applam"
                    [title]="list.name " class="img-responsive center-block"></a>

                <a routerLink="/product/{{ list.id }}" href="#"><img
                    [src]="list.pictureUrl" class="img-responsive additional-img"
                    alt="additional image"></a>

              </div>
              <div class="caption text-center">

                <h4><a href="image/cache/catalog/product/2-270x297.jpg">{{list.name}} </a></h4>
                
                <div class="rating"> <span class="fa fa-stack"><i class="fa fa-star-o fa-stack-2x"></i></span>
                  <span class="fa fa-stack"><i class="fa fa-star-o fa-stack-2x"></i></span>
                  <span class="fa fa-stack"><i class="fa fa-star-o fa-stack-2x"></i></span>
                  <span class="fa fa-stack"><i class="fa fa-star-o fa-stack-2x"></i></span>
                  <span class="fa fa-stack"><i class="fa fa-star-o fa-stack-2x"></i></span>
                </div>
                <p class="price"> ${{list.price}}</p>
                <p class="description"><b>Description:</b>{{list.description}}</p>

                <div class="button-group">
                  <button class="acart" data-toggle="tooltip" (click)="addItemToBasket(indexOfelement)" title="Add to Cart" type="button"
                    ><svg width="16px" height="16px">
                      <use xlink:href="#addcart"></use>
                    </svg></button>
                  <!-- <button type="button" data-toggle="tooltip" title="Add to Wish List"
                   ><svg width="16px" height="16px">
                      <use xlink:href="#addwish"></use>
                    </svg></button> -->
                  <!-- <button type="button" data-toggle="tooltip" title="Compare this Product"
                   ><svg width="16px" height="16px">
                      <use xlink:href="#addcompare"></use>
                    </svg></button>
                  <div class="bquickv" data-toggle="tooltip" title="Quick View"></div> -->
                </div>
              </div>

            </div>
          </div>
         
    
    
        </div>
        <div class="row pagi">
          <div class="col-sm-6 col-xs-6 text-left"></div>
          <div class="col-sm-6 col-xs-6 text-right tot">
            <pagination-controls (pageChange)="pageChange($event)"></pagination-controls>
          </div>          
        </div>
        </div>
      </div>
    </div>
  </div>
  <app-footer></app-footer>

  