import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthserviceService {
  isLoggedIn = false;

  constructor() { }
  login(){
    if(localStorage.getItem('token'))
{
  this.isLoggedIn = true;
}    
   
  }

  logout(){
    this.isLoggedIn = false;
  }
}
