import { Component, OnInit } from '@angular/core';
import { prductApiService } from '../services/productapi.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
details?:any;
  constructor(private _prductApiService: prductApiService) { }

  ngOnInit(): void {
    this._prductApiService.addressDetails().subscribe(data=>{
      this.details = data;
     
      });
  }

}
