import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { prductApiService } from '../services/productapi.service';
import { Register } from '../interfacelist/register';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  // registerForm?: FormGroup;
  register?: Register[];
  loading = false;
  submitted = false;

  registerForm: FormGroup = this.formBuilder.group({
    displayName: ['', Validators.required],
    lastname: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    telephone: ['', [Validators.required, Validators.minLength(10)]],
    password: ['', [Validators.required, Validators.minLength(10)]],
    confirm: ['', [Validators.required, Validators.minLength(10)]]
  });

  constructor(private formBuilder: FormBuilder, private router: Router, private _prductApiService: prductApiService) { }

  ngOnInit() {
    console.log(this.formBuilder);

  }
  onSubmit() {
    if (this.registerForm.valid) {
      if (this.registerForm.value['password'] == this.registerForm.value['confirm']) {
        this.submitted = true;
        this.loading = true;
        this.registerForm?.removeControl('telephone');
        this.registerForm?.removeControl('confirm');
        this.registerForm?.removeControl('lastname');

        this._prductApiService.postRegister(this.registerForm?.value)
          .subscribe(data => {
            Swal.fire({
              text: 'Register Successfully !!!',
                icon: 'success',
                timer:2000
            });
            if(data)
            {
              localStorage.setItem('token', data.token);
              localStorage.setItem('email', data.email);
              localStorage.setItem('displayName', data.displayName);
              localStorage.setItem('userid',data.id);
              
              this.router.navigate(['/home']);
            }            
          });
      }
      else
      {
        Swal.fire({
          text: 'Password miss match',
            icon: 'info',
            timer:2000
        });
        console.log("Password miss match");
      }
    }
    else {
      Swal.fire({
        text: 'Enter correct Data',
          icon: 'warning',
          timer:2000
      });
      console.log("Enter correct Data");

    }
  }
}