<!-- <div class="mt-4" [formGroup]="checkoutForm"> -->
<!-- <div class="d-flex justify-content-between align-items-center">
        <h4>Shipping address</h4>
        <button 
            (click)="saveUserAddress()" 
            class="btn btn-outline-success mb-3"
            [disabled]="!checkoutForm.get('addressForm').valid || !checkoutForm.get('addressForm').dirty"
        >
            Save as default address
        </button>
    </div> -->
<div class="col-sm-12 mt-4">
    
    <div class="row">
        <div class="col-sm-3"></div>
        <div class="col-sm-6">
            <form [formGroup]="addressForm" (ngSubmit)="onSubmit()">
                <div class=" col-sm-6">
                    <label  class="col-sm-6 ">First Name</label>
                    <input class="col-sm-6 form-control" formControlName="firstName" required>
                </div>
                <div class="col-sm-6">
                    <label class="col-sm-6">Last Name</label>
                    <input class="col-sm-6 form-control" formControlName="lastName" required>
                </div>
                <div class="col-sm-6">
                    <label class="col-sm-6">Street</label>
                    <input class="col-sm-6 form-control" formControlName="street" required>
                </div>
                <div class="col-sm-6">
                    <label class="col-sm-6">City</label>
                    <input class="col-sm-6 form-control" formControlName="city" required>
                </div>
                <div class="col-sm-6">
                    <label class="col-sm-6">State</label>
                    <input class="col-sm-6 form-control" formControlName="state" required>
                </div>
                <div class="col-sm-6">
                    <label class="col-sm-6">Zip Code</label>
                    <input class="col-sm-6 form-control" formControlName="zipCode" required>
                </div>
                <div class="row justify-content-center">
                    <div class="col-sm-4"></div>
                    <div class="col-sm-4 btnAlign" >
                        <button class="btn btn-success" type="submit">Save</button>
                    </div>

                </div>
            </form>
        </div>
    </div>
    <div class="pull-right">
    <button class="btn btn-primary" [disabled]="addressForm.invalid" (click)="onNext()"> Next</button>
</div>
</div>
<!-- </div> -->

<!-- <div class="float-none d-flex justify-content-between flex-column flex-lg-row mb-5">
    <button class="btn btn-outline-primary" routerLink="/basket">
        <i class="fa fa-angle-left"></i> Back to basket
    </button>
    <button [disabled]="checkoutForm.get('addressForm').invalid" class="btn btn-primary" cdkStepperNext>
        Go to delivery <i class="fa fa-angle-right"></i>
    </button>
</div> -->