<app-header></app-header>

<div class="container">
    <div class="row">
        <div id="content" class="col-sm-12">
            <h3>Home->About Us</h3>
            <h2  class="heading text-center">About our company<hr /></h2>
            <p>We pride ourselves on providing our customers with an impressive and functional online presence. We are a dynamic, passionate and fast growing company in information technology, offering the best customer solutions and helping maximize their organization’s productivity. We believe in making significant moments into magical experiences. We specializes in providing you with the best keys for your business system needs. As our customers, we take their problems and goals very seriously and develop solutions that best suit their needs.</p>
            <p>Our company has paved the way for companies to modernize their infrastructure and optimize their workflows like never before. We offer a wide range of technology options to increase efficiency, scalability and enhance security for future growth. With our specialized resources and knowledge, we contribute to increasing the productivity of your company. We are passionate about helping brands find their creative voices and laying the groundwork by offering the best of our IT services. Our company aims to help our customers thrive in a multi-channel world that is constantly changing and harness their incredible strengths to create a personalized road map to success.</p>
        </div>
    </div>
</div>
<app-footer></app-footer>