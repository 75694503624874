import {Injectable} from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient} from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class prductApiService
{
    //API_URL = 'http://192.168.100.4:90/';
    constructor(private httpclient: HttpClient){ }
    getComments():Observable<any>{
        return this.httpclient.get(environment.apiurl+'products')
    }
    getSingleProduct(id:number):Observable<any>{
        return this.httpclient.get(environment.apiurl+`products/${id}`)
    }
    getPriceAsc():Observable<any>{
        return this.httpclient.get(environment.apiurl+'products?sort=priceAsc')
    }
    getPriceDesc():Observable<any>{
        return this.httpclient.get(environment.apiurl+'products?sort=priceDesc')
    }
    getPagenationData(pageSize :number,pageIndex:number){
        return this.httpclient.get(environment.apiurl+`products?pageSize=${pageSize}&pageIndex=${pageIndex}`)
    }
    getSortPagenationData(pageSize :number,pageIndex:number,type:string){
        return this.httpclient.get(environment.apiurl+`products?pageSize=${pageSize}&pageIndex=${pageIndex}&sort=${type}`)
    }
    getBrandData(){
        return this.httpclient.get(environment.apiurl+`products/brands`)
    }
    getBrandDataByID(pageSize :number,pageIndex:number,brandid: number){
        return this.httpclient.get(environment.apiurl+`products?pageSize=${pageSize}&pageIndex=${pageIndex}&brandId=${brandid}`)
    }
    getTypeData(){
        return this.httpclient.get(environment.apiurl+`products/types`)
    }
    getTypeDataByID(pageSize :number,pageIndex:number,typeid: number){
        return this.httpclient.get(environment.apiurl+`products?pageSize=${pageSize}&pageIndex=${pageIndex}&typeId=${typeid}`)
    }
    getBrandTypeDataByID(pageSize :number,pageIndex:number,typeid: number,brandid: number){
        return this.httpclient.get(environment.apiurl+`products?pageSize=${pageSize}&pageIndex=${pageIndex}&typeId=${typeid}&brandId=${brandid}`)
    }
    postRegister(data:any):Observable<any>{
        const body=JSON.stringify(data);
      
        return this.httpclient.post(environment.apiurl+'account/register', data)
    }
    postLogin(data:any):Observable<any>{
        const body=JSON.stringify(data);
        
        return this.httpclient.post(environment.apiurl+'account/login', data)
    }
    getToCart(basketid:string){
        return this.httpclient.get(environment.apiurl+`basket?id=${basketid}`)
    }
    postAddUpdateCart(data:any):Observable<any>{
        // const body=JSON.stringify(data);
        return this.httpclient.post(environment.apiurl+'basket',data)
    }
    deleteCart(basketid:string){
        return this.httpclient.delete(environment.apiurl+`basket?id=${basketid}`)
    }
    productSlider(){
        return this.httpclient.get('http://yabazthampi-001-site1.htempurl.com/api/AdminSlider/getSliders');
    }
    addressDetails(){
        return this.httpclient.get('http://yabazthampi-001-site1.htempurl.com/api/AdminContactDetail/getContacts');
    }
    menuDetails(){
        return this.httpclient.get('http://yabazthampi-001-site1.htempurl.com/api/AdminDynamicMenu/getMenus');
    }
    logo(){
        return this.httpclient.get('http://yabazthampi-001-site1.htempurl.com/api/AdminLogo/getLogos');
    }
        
}