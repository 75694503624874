import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
// import { Addtocart } from '../interfacelist/productlist';
import { prductApiService } from '../services/productapi.service';
import { Router } from '@angular/router';
import {BasketService} from '../basket/basket.service';
declare function related() : any;

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit {
  id: any;
  adddata?: any;
  // cartdata?: Addtocart[];
  passdata?:any;
  counter=2;
  display?:any;
  constructor(private route:ActivatedRoute,private _prductApiService: prductApiService,private router: Router,private BasketService:BasketService) { }
  singleproductlist: any[]=[];
  ngOnInit(){
    this.route.paramMap.subscribe(data=>{     
      this.id = data.get('id');
    });
    this._prductApiService.getSingleProduct(+this.id)
    .subscribe(data=>{
        this.singleproductlist.push(data);
        this.adddata= this.singleproductlist;               
      }
    );
  }
  increment(){
    this.counter++;
  }
  decrement(){
    if(this.counter >2)
    {
    this.counter--;
    }   
  }
  cartadd(){
    
    this.BasketService.addItemToBasketSingle(this.adddata[0],this.counter); 
  }

}
