import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IAddress } from '../interfacelist/address';
import { IOrderToCreate } from '../interfacelist/order';
import { IDeliveryMethod } from './deliveryMethods';
// import { IOrderToCreate } from '../shared/models/order';

@Injectable({
  providedIn: 'root'
})
export class CheckoutService {
  baseUrl = environment.apiurl;

  constructor(private http: HttpClient) { }

  public addressValid = new BehaviorSubject<any>(null);
  public deliveryMethod = new BehaviorSubject<any>(null);
  public review = new BehaviorSubject<any>(null);
  public orderConfirm = new BehaviorSubject<any>(null);
  public checkOutHeader = new BehaviorSubject<string>('');
  getDeliveryMethods() {
    return this.http.get(this.baseUrl + 'Orders/deliveryMethods');
  }
  getUserAddress() {
    return this.http.get(this.baseUrl + 'account/address');
  }

  updateUserAddress(address: object) {
    return this.http.put(this.baseUrl + 'account/address', address);
  }

  createOrder(order: any) {
    return this.http.post(this.baseUrl + 'orders', order);
  }
  clearData(){
    this.addressValid.next(null);
this.deliveryMethod.next(null);
this.review.next(null);
this.orderConfirm.next(null);

  }
}