import { Component } from '@angular/core';
import { BasketService } from './basket/basket.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'E-Commerce-Kayal';
  onActivate(event:any) {
    window.scroll(0,0);
}
constructor(private basketService: BasketService){}
ngOnInit(): void{
  const basketId= localStorage.getItem('basket_id');
 
  if(basketId)
  {
    if(basketId !='undefined')
    {
      this.basketService.getBasket(basketId).subscribe(() => {
        console.log('intialised basket');
      }, error =>{
        console.log(error);
      })
    }
    else
    {
    console.log('basket id  not created');
    }
  } 

}
  
}
