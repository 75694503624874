import { v4 as uuidv4 } from 'uuid';
export interface IBasket{
    id: string;
    items:IBasketItem[];
    clientSecret?: string;
    paymentIntentId?: string;
    deliveryMethodId?: number;
    shippingPrice?: number;
}
export interface IBasketItem{
    id?: number;
    productName?: string;
    price?: string;
    quantity?: number;
    pictureUrl?:string;    
    brand?: string;
    type?:string;
}
export class Basket implements IBasket {
    id = uuidv4();
    items: IBasketItem[] =[];   
}
export interface IBasketTotals {
    gstAmount: number;
    subTotal: number;
    grantTotal: number;    
}