import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { BasketService } from 'src/app/basket/basket.service';
import { IBasket } from 'src/app/interfacelist/basket';
import { CheckoutService } from '../checkout.service';

@Component({
  selector: 'app-checkout-summary',
  templateUrl: './checkout-summary.component.html',
  styleUrls: ['./checkout-summary.component.css']
})
export class CheckoutSummaryComponent implements OnInit {
  basket$?: Observable<IBasket>;
  item?:any[]=[];
  subTotal : number = 0;
  gstAmount : number = 0;
  grantTotal : number =0;
  constructor(private basketService: BasketService,private checkoutService:CheckoutService) { }  

  ngOnInit(): void {
    // this.checkoutService.checkOutHeader.next('Review');
    this.basket$ =this.basketService.basket$;   
    this.basket$.subscribe(data=>{ 
      if(data){       
       this.item=data.items;    
       for(let i=0;i<this.item.length;i++){
         this.subTotal = this.subTotal+(this.item[i].price * this.item[i].quantity);
       }
       this.gstAmount = (this.subTotal *18)/100;
       this.grantTotal = this.subTotal + this.gstAmount;
    }
      })
   
  }
  // onPayment(){
  //   this.checkoutService.review.next(true);
  // }
  createPaymentIntent() {
    this.checkoutService.review.next(true);
    return this.basketService.createPaymentIntent().subscribe((response: any) => {
      // this.appStepper.next();
    }, error => {
      console.log(error);
    })
  }
}
