import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { IBasket } from '../interfacelist/basket';
import { BasketService } from './basket.service';

@Component({
  selector: 'app-basket',
  templateUrl: './basket.component.html',
  styleUrls: ['./basket.component.css']
})
export class BasketComponent implements OnInit {
  basket$?: Observable<IBasket>;
  item?:any[];
  constructor(private basketService: BasketService) { }

  ngOnInit(): void {
    this.basket$ =this.basketService.basket$;
    // const basketId= localStorage.getItem('basket_id');
   
    this.basket$.subscribe(data=>{ 
        this.item=data.items;         
        
                
      })
   
  }

}
