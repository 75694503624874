
<div *ngIf="basket$ | async">
<div id="checkout-cart" class="container">
    <!-- <ul class="breadcrumb">
        <li><a href="#"><i
                    class="fa fa-home"></i></a></li>
        <li><a href="#">Shopping
                Cart</a></li>
    </ul> -->
    <div class="row">
        <div id="content" class="col-sm-12">
            <!-- <h1 class="heading">Shopping Cart              
            </h1> -->
            <form action="#"
                method="post" enctype="multipart/form-data">
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th class="text-center table-color"><b>Image</b></th>
                                <th class="text-left table-color"><b>Product Name</b></th>
                                <th class="text-left table-color"><b>Model</b></th>
                                <th class="text-left table-color"><b>Quantity</b></th>
                                <th class="text-right table-color"><b>Unit Price</b></th>
                                <th class="text-right table-color"><b>Total</b></th>
                            </tr>
                        </thead>
                        <tbody>

                            <tr *ngFor="let item of item">
                                <td class="text-center"> <a
                                        ><img
                                            [src]="item.pictureUrl"
                                             [title]="item.productName"
                                            class="img-thumbnail" style="height: 50px; width: 50px;" /></a> </td>
                                <td class="text-left"><a
                                       >{{item.productName}}</a> </td>
                                <td class="text-left">{{item.type}}</td>
                                <td class="text-left">
                                    <div class="input-group btn-block" style="max-width: 200px;">
                                        <label type="text" name="quantity[29]"  size="1"
                                            style="text-align:center" >{{item.quantity}}</label>
                                        </div>
                                </td>
                                <td class="text-right">Rs. {{item.price}}</td>
                                <td class="text-right">Rs.{{item.price*item.quantity}}</td>
                            </tr>
                        </tbody>

                    </table>
                </div>
            </form>
         
            <br />
            <div class="row">
                <div class="col-sm-4 col-sm-offset-8">
                    <table class="table table-bordered">
                        <tr>
                            <td class="text-right" style="color: #605963;"><b>Sub-Total:</b></td>
                            <td class="text-right">Rs.{{subTotal}}</td>
                        </tr>
                        <tr>
                            <td class="text-right" style="color: #605963;"><b>Gst Amount:</b></td>
                            <td class="text-right">Rs. {{gstAmount}}</td>
                        </tr>
                        <tr>
                            <td class="text-right" style="color: #605963;"><b>Total:</b></td>
                            <td class="text-right">Rs. {{grantTotal}}</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="buttons clearfix">
                <div class="pull-right"><a
                        class="btn btn-default" (click)="createPaymentIntent()">Go To Payment</a></div>
            </div>
        </div>
    </div>
</div>
</div>
