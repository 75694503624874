<app-header></app-header>
<script>
  $("#slideshow0 > div:gt(0)").hide();

setInterval(function() { 
  $('#slideshow0 > div:first')
  .fadeOut(1000)
  .next()
  .fadeIn(1000)
  .end()
  .appendTo('#slideshow0');
}, 3000);
</script>
<div id="common-home" class="container-fluid">
  <div class="row">
    <div id="content" class="col-sm-12">
      <div class="slide-padding">
        <div class="homeslide">
          <div  class="">
            <div class="text-center">
             
                <img
                  src="assets/images/kayal-slider/Front-Page-silder-1.jpg"
                  alt="slider1"
                  class="img-responsive"
              />
            </div>
            <!-- <div class="text-center">
              <a href="#"
                ><img
                  src="assets/images/kayal-slider/Front-Page-silder-2.jpg"
                  alt="slider2"
                  class="img-responsive"
              /></a>
            </div>
            <div class="text-center">
              <a href="#"
                ><img
                  src="assets/images/kayal-slider/Front-Page-silder-3.jpg"
                  alt="slider3"
                  class="img-responsive"
              /></a>
            </div>
            <div class="text-center">
              <a href="#"
                ><img
                  src="assets/images/kayal-slider/Front-Page-silder-4.jpg"
                  alt="slider4"
                  class="img-responsive"
              /></a>
            </div> -->
          </div>
        </div>
      </div>
      
      <div class="container">
        <div class="row">
          <div  class="container">
            <div  class="container">
            <div class="row">
            <div class="col-sm-12 offerbanner"></div><h2 class="heading text-center">
              Categories products
              <hr />
            </h2></div></div>
            <div class="col-sm-12 offerbanner">
              <div class="col-sm-4">
                
                  <img src="assets/images/cache/catalog/3 Banners/kumkum_212.jpg" alt="3 banners"
                    class="img-responsive" />
                
              </div>
            
            
              <div class="col-sm-4">
                
                  <img src="assets/images/cache/catalog/3 Banners/adathoda.jpg" alt="3 banners"
                    class="img-responsive" />
                
              </div>
           
              <div class="col-sm-4">
                
                  <img src="assets/images/cache/catalog/3 Banners/dry-chilli_1-1.jpg" alt="First Banner"
                    class="img-responsive" />
                
              </div>
            
            <!-- <div class="col-sm-12 offerbanner">
              <div class="">
                <a href="#">
                  <img src="assets/images/cache/catalog/3 Banners/kumkum_2.jpg" alt="First Banner"
                    class="img-responsive" />
                </a>
              </div>
            </div> -->
          </div>
        </div>
      </div>


      <div class="">
        <!-- <div class="tab-content pro-nepr">
          <div class="tab-pane active" id="featurep">
            <div class="pro-nepr">
              <div class="row thummargin">
                <div class="col-xs-12">
                  <div class="col-xs-4">
                    <div class="alert alert-danger alert-dismissible fade in">
                      <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                      <strong>Danger!</strong> This alert box could indicate a dangerous or potentially negative
                      action.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <div id="snackbar" *ngIf="errormsg" class="errormsg">{{errormsg}}</div>
        <div class="container pro-bg">
          <h2 class="heading text-center">
            Treding products
            <hr />
          </h2>
          <ul class="nav nav-tabs pro-tab text-center">
            <li class="active">
              <a href="#featurep" data-toggle="tab">Featured</a>
            </li>
            <li class="tabl">
              <a href="#bestp" data-toggle="tab">BestSeller</a>
            </li>
            <li><a href="#latestp" data-toggle="tab">Latest</a></li>
          </ul>
          
          <div class="tab-content pro-nepr">
            <div class="tab-pane active" id="featurep">
              <div class="pro-nepr">
                <div class="row thummargin">
                  <div class="col-xs-12">
                    <div class="col-sm-3" style="float: left;"
                      *ngFor="let list of listcomments;let indexOfelement=index;">
                      <div class="product-thumb transition">
                        <div class="image">
                          <a routerLink="/product/{{ list.id }}"><img [src]="list.pictureUrl" [alt]="list.name"
                              [title]="list.name" class="img-responsive center-block" /></a>

                          <a routerLink="/product/{{ list.id }}"><img [src]="list.pictureUrl"
                              class="img-responsive additional-img" alt="additional image" /></a>

                          <span class="salepro">sale</span>
                        </div>
                        <div class="caption text-center">
                          <h4>
                            <a routerLink="/product/{{ list.id }}">{{list.name}}</a>
                          </h4>
                          <!-- <div class="rating">
                              <span class="fa fa-stack"
                                ><i class="fa fa-star-o fa-stack-2x"></i
                              ></span>
                              <span class="fa fa-stack"
                                ><i class="fa fa-star-o fa-stack-2x"></i
                              ></span>
                              <span class="fa fa-stack"
                                ><i class="fa fa-star-o fa-stack-2x"></i
                              ></span>
                              <span class="fa fa-stack"
                                ><i class="fa fa-star-o fa-stack-2x"></i
                              ></span>
                              <span class="fa fa-stack"
                                ><i class="fa fa-star-o fa-stack-2x"></i
                              ></span>
                            </div> -->
                          <p class="price">
                            <span class="price-new">${{list.price}}</span>
                            <!-- <span class="price-old">$122.00</span> -->
                          </p>
                          <div class="button-group">
                            <button class="acart" data-toggle="tooltip" title="Add to Cart" type="button"
                              (click)="addItemToBasket(indexOfelement)">
                              <svg width="16px" height="16px">
                                <use xlink:href="#addcart"></use>
                              </svg>
                            </button>
                            <!-- <button
                                type="button"
                                data-toggle="tooltip"
                                title="Add to Wish List"
                              >
                                <svg width="16px" height="16px">
                                  <use xlink:href="#addwish"></use>
                                </svg>
                              </button> -->
                            <!-- <button
                                type="button"
                                data-toggle="tooltip"
                                title="Compare this Product"
                              >
                                <svg width="16px" height="16px">
                                  <use xlink:href="#addcompare"></use>
                                </svg>
                              </button> -->
                            <div data-toggle="tooltip" title="Quick View"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>



            <div class="tab-pane" id="latestp">
              <div class="pro-nepr">
                <div class="row thummargin">
                  <div class="col-xs-12">
                    <div class="col-sm-3" style="float: left;"
                      *ngFor="let list of ascproduct; let indexOfelement=index;">
                      <div class="product-thumb transition">
                        <div class="image">
                          <a routerLink="/product/{{ list.id }}"><img [src]="list.pictureUrl" [alt]="list.name" [title]="list.name"
                              class="img-responsive center-block" /></a>

                          <a routerLink="/product/{{ list.id }}"><img [src]="list.pictureUrl" class="img-responsive additional-img"
                              alt="additional image" /></a>

                          <span class="salepro">sale</span>
                        </div>
                        <div class="caption text-center">
                          <h4>
                            <a routerLink="/product">{{list.name}}</a>
                          </h4>
                          <p class="price">
                            <span class="price-new">${{list.price}}</span>
                            <!-- <span class="price-old">$122.00</span> -->
                          </p>

                          <div class="button-group">
                            <!-- <button class="acart" data-toggle="tooltip" title="Add to Cart" type="button"
                              (click)="addItemToBasket(indexOfelement)">
                              <svg width="16px" height="16px">
                                <use xlink:href="#addcart"></use>
                              </svg>
                            </button> -->
                            <div data-toggle="tooltip" title="Quick View"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>


            <div class="tab-pane" id="bestp">
              <div class="pro-nepr">
                <div class="row thummargin">
                  <div class="col-xs-12">
                    <div class="col-sm-3" style="float: left;"
                      *ngFor="let list of descproduct; let indexOfelement=index;">
                      <div class="product-thumb transition">
                        <div class="image">
                          <a routerLink="/product/{{ list.id }}"><img [src]="list.pictureUrl" [alt]="list.name" [title]="list.name"
                              class="img-responsive center-block" /></a>

                          <a routerLink="/product/{{ list.id }}"><img [src]="list.pictureUrl" class="img-responsive additional-img"
                              alt="additional image" /></a>

                          <span class="salepro">sale</span>
                        </div>
                        <div class="caption text-center">
                          <h4>
                            <a routerLink="/product">{{list.name}}</a>
                          </h4>
                          <p class="price">
                            <span class="price-new">${{list.price}}</span>
                          </p>

                          <div class="button-group">
                            <!-- <button class="acart" data-toggle="tooltip" title="Add to Cart" type="button"
                              (click)="addItemToBasket(indexOfelement)">
                              <svg width="16px" height="16px">
                                <use xlink:href="#addcart"></use>
                              </svg>
                            </button> -->
                            <div data-toggle="tooltip" title="Quick View"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



      <div class="container">
        <div id="banner0">
          <div class="banner-effect">
            <a routerLink="/product"><img src="assets/images/cache/catalog/Home Page Banner/center-banner-1140x250.png"
                alt="banner" class="img-responsive" /></a>
          </div>
        </div>
      </div>

      <div class="container pro-nepr">
        <h3 class="heading">
          Specials
          <hr />
        </h3>
        <div class="row thummargin">
         
            <div class="col-xs-12">
                <div class="col-sm-2" style="float: left;"
                  *ngFor="let list of ascproduct; let indexOfelement=index;">
                  <div class="product-thumb transition">
                    <div class="image">
                      <a routerLink="/product/{{ list.id }}"><img [src]="list.pictureUrl" [alt]="list.name" [title]="list.name"
                          class="img-responsive center-block" /></a>

                      <a routerLink="/product/{{ list.id }}"><img [src]="list.pictureUrl" class="img-responsive additional-img"
                          alt="additional image" /></a>

                      <span class="salepro">sale</span>
                    </div>
                    <div class="caption text-center">
                      <h4>
                        <a routerLink="/product">{{list.name}}</a>
                      </h4>
                      <p class="price">
                        <span class="price-new">${{list.price}}</span>
                        <!-- <span class="price-old">$122.00</span> -->
                      </p>

                      <div class="button-group">
                        <!-- <button class="acart" data-toggle="tooltip" title="Add to Cart" type="button"
                          (click)="addItemToBasket(indexOfelement)">
                          <svg width="16px" height="16px">
                            <use xlink:href="#addcart"></use>
                          </svg>
                        </button> -->
                        <div data-toggle="tooltip" title="Quick View"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>                    
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>