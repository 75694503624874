<!-- <div *ngIf="(basket$ | async) === null">
    <p><span>There is no product in your system</span></p>
</div>
 <div *ngIf="basket$ | async">
    <ul class="dropdown-menu pull-right">
        <li>
            <table class="table table-striped" *ngFor="let item of (basket$ | async).items">
                <tr>
                    <td class="text-center"> <a href="#"><img [src]="item.pictureUrl"
                                [alt]="item.pictureUrl" title="madurampappad" class="img-thumbnail" /></a> </td>
                    <td class="text-left"><a href="#">{{item.productName}}</a> </td>
                    <td class="text-right">{{item.quantity}}</td>
                    <td class="text-right">${{item.price}}</td>
                    <td class="text-center"><button type="button" title="Remove" class="btn btn-danger btn-xs"><i
                                class="fa fa-times"></i></button></td>
                </tr>
            </table>
        </li>
    </ul>
</div> -->
<!-- <app-header></app-header> -->

<div id="checkout-cart" class="container">
    <ul class="breadcrumb">
        <li><a href="#"><i
                    class="fa fa-home"></i></a></li>
        <li><a href="#">Shopping
                Cart</a></li>
    </ul>
    <div class="row">
        <div id="content" class="col-sm-12">
            <h1 class="heading">Shopping Cart
                &nbsp;(1.00kg)
            </h1>
            <form action="#"
                method="post" enctype="multipart/form-data">
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <td class="text-center">Image</td>
                                <td class="text-left">Product Name</td>
                                <td class="text-left">Model</td>
                                <td class="text-left">Quantity</td>
                                <td class="text-right">Unit Price</td>
                                <td class="text-right">Total</td>
                            </tr>
                        </thead>
                        <tbody >
                            <tr *ngFor = "let list of item;">
                                <td class="text-center"> <a
                                        href="#"><img
                                            [src]="list.pictureUrl"
                                            [alt]="list.pictureUrl" [title]="list.productName"
                                            class="img-thumbnail" /></a> </td>
                                <td class="text-left"><a
                                        href="#">{{list.productName}}</a> </td>
                                <td class="text-left">{{list.type}}</td>
                                <td class="text-left">
                                    <div class="input-group btn-block" style="max-width: 200px;">
                                        <input type="text" name="quantity[29]" value="1" size="1"
                                            class="form-control" />
                                        <span class="input-group-btn">
                                            <button type="submit" data-toggle="tooltip" title="Update"
                                                class="btn btn-primary cartbt"><i
                                                    class="fa fa-refresh"></i></button>
                                            <button type="button" data-toggle="tooltip" title="Remove"
                                                class="btn btn-danger"><i
                                                    class="fa fa-times-circle"></i></button>
                                        </span>
                                    </div>
                                </td>
                                <td class="text-right">{{list.Price}}</td>
                                <td class="text-right">$122.00</td>
                            </tr>
                        </tbody>

                    </table>
                </div>
            </form>
        
            <div class="row">
                <div class="col-sm-4 col-sm-offset-8">
                    <table class="table table-bordered">
                        <tr>
                            <td class="text-right" style="color: #3bac44;"><b>Sub-Total:</b></td>
                            <td class="text-right">$100.00</td>
                        </tr>
                        <tr>
                            <td class="text-right" style="color: #3bac44;"><b>Eco Tax (-2.00):</b></td>
                            <td class="text-right">$2.00</td>
                        </tr>
                        <tr>
                            <td class="text-right" style="color: #3bac44;"><b>VAT (20%):</b></td>
                            <td class="text-right">$20.00</td>
                        </tr>
                        <tr>
                            <td class="text-right" style="color: #3bac44;"><b>Total:</b></td>
                            <td class="text-right">$122.00</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="buttons clearfix">
                <div class="pull-left"><a
                   routerLink="/"
                        class="btn btn-default">Continue Shopping</a></div>
                <div class="pull-right"><a
                    routerLink="/checkout"
                        class="btn btn-primary">Checkout</a></div>
            </div>
        </div>
    </div>
</div>
<!-- <app-footer></app-footer> -->
