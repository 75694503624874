import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BasketService } from 'src/app/basket/basket.service';
import { CheckoutService } from '../checkout.service';
import { IDeliveryMethod } from '../deliveryMethods';

@Component({
  selector: 'app-checkout-address',
  templateUrl: './checkout-address.component.html',
  styleUrls: ['./checkout-address.component.css']
})
export class CheckoutAddressComponent implements OnInit {
  deliveryForm: FormGroup = this.fb.group({
    deliveryMethod: ['', Validators.required]
  });
  deliveryMethods?: any;
  deliveryData: any;
  deliveryMethodData?: any;

  constructor(private checkoutService: CheckoutService, private fb: FormBuilder, private basketService: BasketService) { }

  ngOnInit(): void {
    // this.checkoutService.checkOutHeader.next('Delivery Method');
    this.checkoutService.getDeliveryMethods().subscribe(data => {
      this.deliveryMethods = data;
    })
  }
  setShippingPrice(deliveryMethod: IDeliveryMethod) {
    this.deliveryMethodData = deliveryMethod;
    this.basketService.setShippingPrice(deliveryMethod);
  }
  onReview() {
    if (this.deliveryForm.valid) {
      this.checkoutService.deliveryMethod.next(this.deliveryMethodData);
    }
  }
}
