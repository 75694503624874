import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { BasketService } from '../basket/basket.service';
import { AuthserviceService } from '../guard/authservice.service';
import { IBasket } from '../interfacelist/basket';

@Component({
  selector: 'app-shopcart',
  templateUrl: './shopcart.component.html',
  styleUrls: ['./shopcart.component.css']
})
export class ShopcartComponent implements OnInit {
  basket$?: Observable<IBasket>;
  item?:any[]=[];
  subTotal : number = 0;
  total : number =0;
  gstAmount : number = 0;
  grantTotal : number =0;
  loading = false;
  constructor(private basketService: BasketService,private router: Router, private authService: AuthserviceService) { }  

  ngOnInit(): void {
    this.basket$ =this.basketService.basket$;   
    this.basket$.subscribe(data=>{
      this.loading = true;
      if(data){       
       this.item=data.items;    
       for(let i=0;i<this.item.length;i++){
         this.subTotal = this.subTotal+(this.item[i].price * this.item[i].quantity);
       }
       this.total = this.subTotal;
      
       
       this.gstAmount = (this.total *18)/100;
       this.grantTotal = this.total + this.gstAmount;
       
       //this.calculate(this.gstAmount,this.subTotal,this.grantTotal);    
    }
      })
   
  }
  // calculate(gstAmount:any,subTotal:any,grantTotal:any){
  //   this.basketService.calculateTotals(gstAmount,subTotal,grantTotal);
  // }
  removeItemFromBasket(item:any)
  {
    //this.basket$ =this.basketService.basket$;
    this.basketService.removeItemFromBasket(item);
    window.location.reload();
     
  }
  onCheckout(){
    this.authService.login();

  }
 
}


