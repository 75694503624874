import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Basket, IBasket,IBasketItem, IBasketTotals } from '../interfacelist/basket';
import { Productlist } from '../interfacelist/productlist';
import { IDeliveryMethod } from '../checkout/deliveryMethods';


@Injectable({
  providedIn: 'root'
})
export class BasketService {
// @Input() basket?: Basket;
baseurl= environment.apiurl;
userid?:any;
shipping = 0;

 basketSource = new BehaviorSubject<IBasket>(null as any);
  basket$ = this.basketSource.asObservable();
  // private basketTotalSource = new BehaviorSubject<IBasketTotals>(null);

  private basketTotalSource = new BehaviorSubject<IBasketTotals>(null as any);
  basketTotal$ = this.basketTotalSource.asObservable();
  constructor(private http:HttpClient) { }
  createPaymentIntent() {
    return this.http.post(environment.apiurl + 'payments/' + this.getCurrentBasketValue().id, {})
      .pipe(
        map((basket: any) => {
          this.basketSource.next(basket);
        })
      )
  }
  getBasket(id: string) {    // console.log(id);    
    return this.http.get(this.baseurl + 'basket?id=' + id).pipe(
      map((basket: any) => {
        this.basketSource.next(basket);
        
      })
    )     
  }

  setBasket(basket: IBasket) {    
    return this.http.post(this.baseurl + 'basket', basket).subscribe((response:any) => {     
      this.basketSource.next(response);        
    }, error => {
      console.log(error);
    });
  }

  getCurrentBasketValue() {  
    return this.basketSource.value;
  }

  addItemToBasket(item: Productlist, quantity = 1) {    
    const itemToAdd: IBasketItem = this.mapProductItemToBasketItem(item, quantity);   
    const basket = this.getCurrentBasketValue() ?? this.createBasket();    
    basket.items = this.addOrUpdateItem(basket.items, itemToAdd, quantity); 
    
       
    this.setBasket(basket);
  }
  addItemToBasketSingle(item: Productlist, quantity: number) {      
    const itemToAdd: IBasketItem = this.mapProductItemToBasketItem(item, quantity);   
    const basket = this.getCurrentBasketValue() ?? this.createBasket();    
    basket.items = this.addOrUpdateItem(basket.items, itemToAdd, quantity); 
    
       
    this.setBasket(basket);
  }
  removeItemFromBasket(item: IBasketItem) {
    const basket = this.getCurrentBasketValue();
    if (basket.items.some(x => x.id === item.id)) {
      basket.items = basket.items.filter(i => i.id !== item.id);
      if (basket.items.length > 0) {
        this.setBasket(basket);
      } else {
        this.deleteBasket(basket); 
      }
    }
  }
  deleteBasket(basket: IBasket) {
    return this.http.delete(this.baseurl + 'basket?id=' + basket.id).subscribe(() => {
      this.basketSource.next(null as any);
      //this.basketTotalSource.next(null);
      localStorage.removeItem('basket_id');      
    }, error => {
      console.log(error);
    })
  }

  deleteLocalBasket(id: string) {
    this.basketSource.next(null as any);
    //this.basketTotalSource.next(null);
    localStorage.removeItem('basket_id');
  }
  private addOrUpdateItem(items: IBasketItem[], itemToAdd: IBasketItem, quantity: number): IBasketItem[] {
  
    const index = items.findIndex(i => i.id === itemToAdd.id);
    if (index === -1) {
      itemToAdd.quantity = quantity;
      items.push(itemToAdd);
    } 
    else {
      items[index].quantity = (items[index].quantity || 0) + quantity;
    }
    return items;
  }

  private createBasket(): IBasket {
   const basket = new Basket();
   this.userid=basket.id;
    localStorage.setItem('basket_id', this.userid);
    return basket;
  }

  private mapProductItemToBasketItem(item: Productlist, quantity: number): IBasketItem {
    return {
      id: item.id,
      productName: item.name,
      price: item.price,
      quantity,
      pictureUrl: item.pictureUrl,      
      brand: item.productBrand,
      type: item.productType
    } 
  }
  setShippingPrice(deliveryMethod: IDeliveryMethod) {
    this.shipping = deliveryMethod.price;
    const basket = this.getCurrentBasketValue();
    basket.deliveryMethodId = deliveryMethod.id;
    basket.shippingPrice = deliveryMethod.price;
    this.calculateTotals();
    this.setBasket(basket);
  }
  private calculateTotals() {
    const basket = this.getCurrentBasketValue();
    const shipping = this.shipping;
    const subtotal = basket.items.reduce((a: any, b:any) => (b.price * b.quantity) + a, 0);
    const total = subtotal + shipping;
    // this.basketTotalSource.next({shipping, total, subtotal});
  }

  // calculateTotals(gstAmount:any,subTotal:any,grantTotal:any) {
    
  //   this.basketTotalSource.next({gstAmount, subTotal, grantTotal});
  // }
}
